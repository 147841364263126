export const ROUTE_CONSTANTS = {
  DASHBOARD: "/",
  CARE_PROGRAM: "/care-program",
  THANK_YOU_APPLY: "/apply-thanks",
  PRIVACY_POLICY_PAGE: "/privacy-policy",
  TERM_AND_CONDITIONS: "/term-and-conditions",
  CONTACT_US: "/contact-us",
  PRIZE_POOL: "/prize-pool",
  LEADER_SHIP: "/leadership-team"
};
