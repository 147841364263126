// components
import FirstHeader from "./FirstHeader";
import Footer from "./Footer";

export const PublicLayout = ({ children }) => {
  return (
    <>
      {/* ------- First Header Component ------- */}
      <FirstHeader />

      <main className="main">
        {/* ------- Children Component ------- */}
        {children}
      </main>
      {/* ------- Footer Component ------- */}
      <Footer />
    </>
  );
};

export default PublicLayout;
