import React from "react";
import { useField, ErrorMessage } from "formik";

function TextField(props) {
  const [field, meta, onChange] = useField(props);
  return (
    <>
      <input
        className={`form-control`}
        {...field}
        {...props}
        autoComplete="off"
      />

      <div className="error">
        <ErrorMessage name={field.name} />
      </div>
    </>
  );
}

export default TextField;
