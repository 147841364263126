import SubHeader from "Components/Atom/SubHeader";
import { Images } from "Shared";
import { useEffect } from "react";
import "./leadership.scss";

export default function Leadership() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SubHeader heading={"Leadership Team"} isFooter={true} />

      <div className="container py-md-5 py-4 leadershipTeam">
        <div className="row mt-4">
          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
            <div className="leadershipCard bg_grey">
              <figure className="mb-0">
                <img
                  src={Images.AmalDavis}
                  width={300}
                  className="img-fluid"
                  alt="Team"
                />
              </figure>
              <div className="leadershipCard--summary">
                <h3 className="h4 text-uppercase">Amal Davis</h3>
                <div className="leadershipCard-text">
                  <p>
                    Amal, the director of Mini Hearts, has a strong background
                    in the corporate world. Amal’s genuine passion lies in
                    helping people, particularly children, which stems from her
                    own experiences growing up in a large family. This deep care
                    and concern for others motivated her to establish Mini
                    Hearts. The primary objective of Mini Hearts, as envisioned
                    by Amal is to not only provide financial assistance to
                    children in need but also to offer comprehensive support to
                    their families. Amal is committed to nurturing Mini hearts
                    into a community-based charity that serves sick children and
                    provides them with the opportunities for a fulfilling life.
                    Her heartfelt dedication to the cause drives her to do her
                    utmost in growing Mini Hearts and ensuring that it becomes a
                    beacon of hope and support for sick children and their
                    families.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* 2nd */}
          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
            <div className="leadershipCard bg_grey">
              <figure className="mb-0">
                <img
                  src={Images.JustinDavis}
                  width={300}
                  className="img-fluid"
                  alt="Team"
                />
              </figure>
              <div className="leadershipCard--summary">
                <h3 className="h4 text-uppercase">Justin Davis</h3>
                <div className="leadershipCard-text">
                  <p>
                    The founder of Wetspot Group, established the company 25
                    years ago and has successfully grown it to serve over 140
                    clients. Wetspot specialises in the manufacturing and
                    installation of natural stone, waterproofing, and tiling. As
                    a father of two little girls, Justin’s involvement in Mini
                    Hearts holds special significance. His personal experiences
                    with his eldest daughter who had a rare condition called
                    pneumothorax at birth, has deepened his connection to
                    charities and the importance of helping children facing rare
                    conditions. This personal journey has driven Justin and his
                    wife Amal to establish Mini Hearts, with the aim of
                    providing support to sick children, particularly those with
                    rare conditions. His primary goal is to provide help, create
                    awareness and support children who are facing rare
                    conditions, ensuring they receive the care and assistance
                    they need.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* 3rd */}
          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
            <div className="leadershipCard bg_grey">
              <figure className="mb-0">
                <img
                  src={Images.MarkGulifa}
                  width={300}
                  className="img-fluid"
                  alt="Team"
                />
              </figure>
              <div className="leadershipCard--summary">
                <h3 className="h4 text-uppercase">Mark Gulifa</h3>
                <div className="leadershipCard-text">
                  <p>
                    Marks recent appointment as Global Chief Revenue and
                    Marketing Officer at Mini Hearts brings a wealth of
                    executive knowledge and experience spanning over 28 years in
                    various industries and business landscapes. His diverse
                    background has equipped him with valuable insights and
                    expertise to drive the organisation forward.. With his
                    mantra that “the journey is the actual destination,” Mark
                    places great importance on fostering a positive and
                    growth-oriented environment for his teams. Mark’s true
                    passion lies in inspiring those around him to continually
                    learn, develop, and grow as individuals. Mark is a proud
                    father of three young children and a passion to assist those
                    children most in need in our community using his skills and
                    expertise to assist Mini Hearts on this journey or making a
                    difference.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* 4th */}
          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
            <div className="leadershipCard bg_grey">
              <figure className="mb-0">
                <img
                  src={Images.FrankMarc}
                  width={300}
                  className="img-fluid"
                  alt="Team"
                />
              </figure>
              <div className="leadershipCard--summary">
                <h3 className="h4 text-uppercase">Frank Marcaccio</h3>
                <div className="leadershipCard-text">
                  <p>
                    Currently, a Business Development Manager of HostPlus,
                    brings a wealth of experience in the financial advice
                    industry. Aside from his professional endeavours, Frank has
                    been deeply involved in soccer since a young age and
                    progressed into a senior player. Currently, Frank divides
                    his time between his role as a Business Manger and his
                    responsibilities as a board member at Mini Hearts. Mini
                    Hearts is an organisation close to Frank’s heart, as he is
                    dedicated to making a positive impact on the lives of
                    children in need.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* 5th */}
          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
            <div className="leadershipCard bg_grey">
              <figure className="mb-0">
                <img
                  src={Images.Colin}
                  width={300}
                  className="img-fluid"
                  alt="Team"
                />
              </figure>
              <div className="leadershipCard--summary">
                <h3 className="h4 text-uppercase">Colin Mackenzie</h3>
                <div className="leadershipCard-text">
                  <p>
                    Colin’s background and career path demonstrate his diverse
                    experience in economics, finance and property development.
                    Growing up in Canada and studying economics at the
                    University of Toronto provided him with a solid foundation
                    in the field. He further enhanced his qualification by
                    completing an MBD and an Advanced Diploma in Building and
                    Construction. Outside of his professional endeavours, Colin
                    is also a dedicated board member of the Mini Hearts
                    Foundation. Given his role as a father of two children, he
                    holds his position in high regard, showcasing his commitment
                    to philanthropy and supporting charitable causes.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* 6th */}
          <div className="col-lg-4 col-md-6  col-sm-6 col-12 mb-4">
            <div className="leadershipCard bg_grey">
              <figure className="mb-0">
                <img
                  src={Images.LeeSmartino}
                  width={300}
                  className="img-fluid"
                  alt="Team"
                />
              </figure>
              <div className="leadershipCard--summary">
                <h3 className="h4 text-uppercase">Lee Samartino</h3>
                <div className="leadershipCard-text">
                  <p>
                    Lee was appointed to the Mini Hearts board in 2023, is
                    currently the Head of Business Growth & Acquisiton at
                    HostPlus, A prominent industry superannuation fund in
                    Australia. With a strong educational foundation and
                    experience in the financial services industry, Lee’s
                    expertise in financial planning adds further value to the
                    board’s strategic decision-making processes. His
                    understanding of risk management ensures that the
                    organisation can navigate potential challenges effectively.
                    In addition to his professional accomplishments, Lee’s
                    passion for soccer and involvement in various clubs
                    demonstrates his commitment to the sport and the community.
                    Lee is a valuable asset to the Mini Hearts board,
                    contributing to its governance, strategic direction, growth
                    initiatives, risk management and marketing efforts.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
