import { Images, LABELS } from "Shared";
import React, { useEffect } from "react";
import "./style.scss";

const ThankyouApply = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="section bg_grey success-wrapper">
        <div className="container card success_card border-0">
          <div className="common_titlebar text-center">
            <h2 className="h2">{LABELS.THANK_YOU}</h2>
          </div>
          <figure className="successicon text-center">
            <img
              src={Images.SuccessImg}
              width={241}
              height={184}
              className="img-fluid"
              alt="success"
            />
          </figure>
          <p>{LABELS.PARAGRAPH}</p>
          <p>
            {LABELS.REGARDS}, <br />
            {LABELS.MINI_HEARTS_MANAGEMENT}
          </p>
        </div>
      </section>
    </>
  );
};

export default ThankyouApply;
