// components
import SubHeader from "Components/Atom/SubHeader";
import MessageForm from "./components/MessageForm";
import AddressComp from "./components/AddressComp";

// libs
import React, { useEffect } from "react";
import "./contact.scss";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SubHeader heading={"Contact us"} isFooter={true} />
      <div className="contact_wrapper">
        <div className="container py-md-5 py-4 common_pages">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <MessageForm />
            </div>
            <div className="col-lg-6">
              <AddressComp />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
