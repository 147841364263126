import React, { useEffect, useRef } from "react";
import WOW from "wowjs";
import { LABELS } from "Shared";

const SupportCommunity = () => {
  const transitionRef = useRef(1);
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  return (
    <>
      <section
        className="section community_secn wow fadeInUp"
        data-wow-duration={`0.${transitionRef.current + 3}s`}
        data-wow-delay={`0.${transitionRef.current + 3}s`}
      >
        <div className="container">
          <div className="small_titlebar text-center">
            <h2 className="h4">{LABELS.SUPPORT_COMMUNITY.toUpperCase()}</h2>
          </div>
          <div className="row my-3 mb-md-3 mb-0">
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-lg-0 mb-4">
              <div className="text-center community_grid_item">
                <h2 className="h2"> &gt; 63%</h2>
                <span>{LABELS.SUPPORT_COMMUNITY_2.toUpperCase()}</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-lg-0 mb-4">
              <div className="text-center community_grid_item">
                <h2 className="h2">{LABELS.STORY_BOARD}</h2>
                <span>{LABELS.HEALTH.toUpperCase()}</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-lg-0 mb-4">
              <div className="text-center community_grid_item">
                <h2 className="h2"> $367K+</h2>
                <span>{LABELS.TOWARDS_CHILDREN.toUpperCase()}</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-lg-0 ">
              <div className="text-center community_grid_item">
                <h2 className="h2"> 26+</h2>
                <span>{LABELS.STAFF_AND_VOLUNTEERS.toUpperCase()}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SupportCommunity;
