import React, { useEffect } from "react";
import { Images } from "Shared";
import WOW from "wowjs";
import "./style.scss";
import { Link } from "react-router-dom";
import { donateLink } from "envData";

const OUR_STORY_MESSAGE = {
  STORY_DESC_P1: `Mini Hearts  dedication to helping children in need is a cause that is unquestionably worth supporting and donating to. With our mission centering on supporting and improving the lives of children aged 0-16 who are suffering from serious illnesses, our organisation continually demonstrates a profound impact on their lives and the lives of their families during these difficult and challenging times.`,
  STORY_DESC_P2: (
    <>
      One of Mini Heart's main objectives is to provide ongoing financial
      assistance directly to these children and their families through our
      unique
      <strong> ‘We Care Program’</strong>. Once approved, weekly payments are
      processed directly to these families who are in need of our support. We
      raise funds through our promotions and sponsors but ultimately your
      donations, your commitment to making a difference and your ongoing support
      has the biggest impact. We have been lucky enough to secure some wonderful
      sponsors who have joined us on this journey and our mission is to support
      as many children as possible, for as long as possible through this unique
      one-of-a-kind program.
    </>
  ),
  STORY_DESC_P3:
    "Countless success stories and testimonials highlight the impact we are having with these children suffering serious illnesses and the transformative powers of our efforts thus far but with your donations and support we can do more.",
  STORY_DESC_P4: (
    <>
      We need ongoing support from Heroes like you to enable us to continually
      support these children and assist us with onboarding as many children as
      we can onto the <strong>‘We Care Program’</strong>.
    </>
  ),
  STORY_DESC_P5: "There are so many children in need of our support.",
  MAKE_DIFFERENCE: "Making A Difference in Children's Lives",
  MAKE_DONATION: "Make A Donation",
  OUR_STORY: "Our Story",
};

const ImageStory = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  return (
    <>
      <section className="herobanner position-relative">
        <figure className="herobanner_card">
          <img
            src={Images.banner_image}
            className="w-100"
            width="1000"
            height="700"
            alt="banner-images"
          />
          <div className="herobanner_card_box ">
            <div className="position-relative herobanner_card_content">
              <h1 className="h1"> {OUR_STORY_MESSAGE.MAKE_DIFFERENCE} </h1>
              <Link
                className="text-center mt-md-5 mt-5 d-flex justify-content-center align-items-center animating_logo"
                type="link"
                aria-current="page"
                target="_self"
                to={donateLink}
              >
                <img
                  src={Images.DonateNow}
                  className="img-fluid donateNow"
                  width="100"
                  alt="Donate Now heart"
                />
              </Link>
            </div>
          </div>
        </figure>
      </section>
      {/* about story secn */}
      <section className="our_story_secn section wow fadeInUp">
        <div className="container">
          <div className="common_titlebar text-center">
            <h2 className="h2 fs-42">{OUR_STORY_MESSAGE.OUR_STORY}</h2>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="our-story_content">
                <p className="text-justify">
                  {OUR_STORY_MESSAGE.STORY_DESC_P1}
                </p>
                <p className="text-justify">
                  {OUR_STORY_MESSAGE.STORY_DESC_P2}
                </p>
                <p className="text-justify">
                  {OUR_STORY_MESSAGE.STORY_DESC_P3}
                </p>
                <p className="text-justify">
                  {OUR_STORY_MESSAGE.STORY_DESC_P4}
                </p>
                <p className="text-justify">
                  {OUR_STORY_MESSAGE.STORY_DESC_P5}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ImageStory;
