// libs
import { useEffect } from "react";
import WOW from "wowjs";

// constants
import {
  Images,
  LABELS,
  REDIRECTION_LINKS_OF_SPONSORS,
  STRING_NUMBER
} from "Shared";

// styles
import { Link } from "react-router-dom";
import "./main-partner.scss";

const MainPartner = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const mainPartners = [
    // {
    //   id: STRING_NUMBER.ONE,
    //   data: Images.main_partner.GIVE_2_THE_KIDS,
    //   imageUrl: GIVE_2_THE_KIDS_DOMAIN,
    // },
    {
      id: STRING_NUMBER.TWO,
      data: Images.main_partner.GIFTA,
      imageUrl: REDIRECTION_LINKS_OF_SPONSORS.GIFTA,
    },
    {
      id: STRING_NUMBER.THREE,
      data: Images.main_partner.HYPE_SOCIETY,
      imageUrl: REDIRECTION_LINKS_OF_SPONSORS.HYPE_SOCIETY,
    },
    {
      id: STRING_NUMBER.FOUR,
      data: Images.main_partner.GRAND_MASTER,
      imageUrl: REDIRECTION_LINKS_OF_SPONSORS.GRAND_MASTER,
    },
    {
      id: STRING_NUMBER.FIVE,
      data: Images.main_partner.SALVO,
      imageUrl: REDIRECTION_LINKS_OF_SPONSORS.SALVO,
    },
    {
      id: STRING_NUMBER.SIX,
      data: Images.main_partner.CREMA_GROUP,
      imageUrl: REDIRECTION_LINKS_OF_SPONSORS.CREMA_GROUP,
    },
    {
      id: STRING_NUMBER.SEVEN,
      data: Images.main_partner.HICKORY,
      imageUrl: REDIRECTION_LINKS_OF_SPONSORS.HICTORY,
    },
    {
      id: STRING_NUMBER.EIGHT,
      data: Images.main_partner.THE_HARDWARE_CLUB,
      imageUrl: REDIRECTION_LINKS_OF_SPONSORS.THE_HARDWARE_CLUB,
    },
    {
      id: STRING_NUMBER.NINE,
      data: Images.main_partner.BARJAYDA,
      imageUrl: REDIRECTION_LINKS_OF_SPONSORS.BARJAYDA,
    },
    {
      id: STRING_NUMBER.TEN,
      data: Images.main_partner.KAPITOL_GROUP,
      imageUrl: REDIRECTION_LINKS_OF_SPONSORS.KAPITOL,
    },
    {
      id: STRING_NUMBER.ELEVEN,
      data: Images.main_partner.LTE,
      imageUrl: REDIRECTION_LINKS_OF_SPONSORS.LTE,
    },
    {
      id: STRING_NUMBER.TWELVE,
      data: Images.main_partner.MAPEL,
      imageUrl: REDIRECTION_LINKS_OF_SPONSORS.MAPEL,
    },
    {
      id: STRING_NUMBER.THIRTEEN,
      data: Images.main_partner.JPS,
      imageUrl: REDIRECTION_LINKS_OF_SPONSORS.JPS,
    },
    {
      id: STRING_NUMBER.FOURTEEN,
      data: Images.main_partner.ASG,
      imageUrl: REDIRECTION_LINKS_OF_SPONSORS.ASG,
    },
    {
      id: STRING_NUMBER.FIFTEEN,
      data: Images.main_partner.SLF,
      imageUrl: REDIRECTION_LINKS_OF_SPONSORS.SLF
    }
  ];

  return (
    <>
      <section className="section main_partner wow fadeInUp">
        <div className="container">
          <div className="row align-items-center pb-md-5 pb-3">
            <div className="col-lg-4">
              <div className="common_titlebar text-left ">
                <h2 className="h2">{LABELS.MAIN_PARTNER.toUpperCase()}</h2>
              </div>
            </div>
          </div>
          {/* TODO: Logo grid to be placed */}
          <div className="common_grid d-grid">
            {mainPartners?.map((main_partner_icon) => (
              <Link
                className="common_grid_card"
                to={main_partner_icon?.imageUrl}
                key={main_partner_icon?.id}
              >
                <img
                  src={main_partner_icon?.data}
                  className="img-fluid"
                  alt="Logos"
                />
              </Link>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default MainPartner;
