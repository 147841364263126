import React from "react";
import "./child-story-card.scss";
import { Images, STORY_DESC_CHAR_LIMIT } from "Shared";
import CustomTooltip from "../CustomTooltip";

const ChildStoryCard = ({ item = null }) => {
  const storyDescription = item?.description || "";
  const isEllipsisNeeded = storyDescription.length > STORY_DESC_CHAR_LIMIT;
  let trimmedStoryDescription = storyDescription.slice(
    0,
    STORY_DESC_CHAR_LIMIT,
  );
  const lastWordIndex = trimmedStoryDescription.lastIndexOf(" ");
  trimmedStoryDescription = storyDescription.slice(0, lastWordIndex);

  return !item ? null : (
    <>
      <div
        className="box childStory-card my-cursor-pointer position-relative"
        onClick={() => {
          if (item?.url) window.open(item?.url, "_self");
        }}
      >
        <figure className="childStory-figure">
          <img
            className="slider-image"
            width="300"
            height={250}
            src={item?.image}
            alt="image_Data"
          />
        </figure>
        <div className="childStory-content d-flex flex-column gap-2">
          <h6 className="h6 text-center">{item?.title}</h6>

          {isEllipsisNeeded ? (
            <CustomTooltip
              placement="bottom-end"
              arrow={false}
              title={storyDescription.slice(lastWordIndex)}
            >
              <p className="trim-care-program-description">
                {trimmedStoryDescription}...
              </p>
            </CustomTooltip>
          ) : (
            <p className="trim-care-program-description">{storyDescription}</p>
          )}
          <div className="d-flex justify-content-between flex-sm-row flex-wrap align-items-center gap-2 childStory-content_footer">
            <span className="footer_title">Proudly Sponsored by</span>
            <em className="footer_img">
              <img
                src={Images.miniHeartFoundation}
                className="img-fluid"
                width="100"
                alt="miniHeart logo"
              />
            </em>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChildStoryCard;
