// libs
import WOW from "wowjs";
import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

// styles
import "./benefits.scss";

// constants
import { Images, LABELS, ROUTE_CONSTANTS } from "Shared";
import CareProgramChild from "Views/CareProgram/CareProgramChild";
import { donateLink, storyBoardURL } from "envData";

const LABLES = {
  BENEFIT_PROGRAM: "Mini Hearts Benefits Program",
  BENEFIT_PROGRAM_DESC:
    "Our Members are driven by compassion and are passionate about making a lasting difference for the health of these kids. We take great pride in our work, as it offers both immediate relief and long-term transformation.",
  DGR_STATUS: "DGR status",
  GIFT_RECIPENT: "Mini Hearts is a deductible gift recipient.",
  EXCLUSIVE: "Exclusive",
  SPREAD_AWARENESS:
    "Spread awareness around struggles that local children are facing to gain support.",
  PROGRAM_STATUS: "We Care Program",
  STORY_BOARD: "Story Board",
};

const BenefitProgram = () => {
  const navigate = useNavigate();
  const transitionRef = useRef(1);

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();

    // Add an event listener to prevent scroll on the iframe
    const iframe = document.querySelector(".videoBox iframe");
    if (iframe) {
      iframe.setAttribute("scrolling", "no");
    }

    return () => {
      // Clean up event listener
      if (iframe) {
        iframe.removeAttribute("scrolling");
      }
    };
  }, []);

  return (
    <>
      <section className="section benefits_secn bg_grey">
        <div className="container">
          <div className="videoBox text-center wow fadeInUp">
            <div className="ratio ratio-16x9">
              <iframe
                src={process.env.REACT_APP_API_YOUTUBE_LINK}
                title="YouTube video"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          {/* video box ends */}

          {/* We care program block - start */}
          <div className="common_titlebar mt-4 text-center">
            <h2 className="h2">
              <span>{LABELS.CARE_PROGRAM.toUpperCase()}</span>
            </h2>
          </div>
          <div className="careProgram_wrapper-content text-start mb-4">
            <p className="text-justify">{LABELS.MINI_HEARTS_MESSAGE}</p>
          </div>
          <CareProgramChild enableLoader={false} />
          <div className="careProgram_wrapper-content text-center mb-4">
            <p className="text-justify">{LABELS.MINI_HEARTS_MESSAGE_II}</p>
            <div className="d-flex w-100 justify-content-end ">
              <button
                type="button"
                className="btn btn-transparent learn_more"
                onClick={() => navigate(ROUTE_CONSTANTS.CARE_PROGRAM)}
              >
                Learn More / Apply
              </button>
            </div>
          </div>
          <div className="position-relative donationBtnGrp text-center">
            <div className="text-center d-flex justify-content-center align-items-center animating_logo">
              <Link
                type="link"
                className="donate_link"
                aria-current="page"
                target="_self"
                to={donateLink}
              >
                <img
                  src={Images.DonateNow}
                  className="img-fluid donateNow"
                  width="120"
                  alt="Donate Now heart"
                />
              </Link>
            </div>
          </div>
          {/* We care program block - end */}

          {/* floating cards */}
          <div className="floating_cards mt-md-5 my-5 mb-sm-5 mb-0 ">
            <div
              className="floating_cards--items wow fadeInUp"
              data-wow-duration={`0.${transitionRef.current + 1}s`}
              data-wow-delay={`0.${transitionRef.current + 1}s`}
            >
              <div className="flex_grp">
                <div className="d-flex align-items-center">
                  <em className="light-red"></em>
                  <span className="badge tagBadge text-bg-secondary"></span>
                </div>
                <div className="cardTitle d-flex flex-column">
                  <h6 className="h6">{LABLES.DGR_STATUS}</h6>
                  <p>{LABLES.GIFT_RECIPENT}</p>
                </div>
              </div>
            </div>
            <div
              className="floating_cards--items wow fadeInUp my-cursor-pointer"
              data-wow-duration={`0.${transitionRef.current + 3}s`}
              data-wow-delay={`0.${transitionRef.current + 3}s`}
              onClick={() => window.open(storyBoardURL, "_self")}
            >
              <div className="flex_grp">
                <div className="d-flex align-items-center">
                  <em className="light-blue"></em>
                  <span className="badge tagBadge text-bg-secondary">
                    {LABLES.EXCLUSIVE}
                  </span>
                </div>
                <div className="cardTitle d-flex flex-column">
                  <h6 className="h6">{LABLES.STORY_BOARD}</h6>
                  <p>{LABLES.SPREAD_AWARENESS}</p>
                </div>
              </div>
            </div>
            <div
              className="floating_cards--items wow fadeInUp my-cursor-pointer"
              data-wow-duration={`0.${transitionRef.current + 5}s`}
              data-wow-delay={`0.${transitionRef.current + 5}s`}
              onClick={() => {
                navigate({
                  pathname: ROUTE_CONSTANTS.CARE_PROGRAM,
                });
              }}
            >
              <div className="flex_grp">
                <div className="d-flex align-items-center">
                  <em className="light-pink"></em>
                  <span className="badge tagBadge text-bg-secondary"></span>
                </div>
                <div className="cardTitle d-flex flex-column">
                  <h6 className="h6">{LABLES.PROGRAM_STATUS}</h6>
                  <p>
                    <strong>{LABLES.PROGRAM_STATUS} &nbsp;</strong>
                    is a program designed by Mini Hearts to help kids and their
                    familes weekly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BenefitProgram;
