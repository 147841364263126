import moment from "moment";
import { useLocation } from "react-router-dom";

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export const errorSnackBar = {
  variant: "error",
  autoHideDuration: 2000,
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};

export const types = [
  {
    name: "svg",
    type: "image/svg+xml",
  },
  {
    name: "jpg",
    type: "image/jpeg",
  },
  {
    name: "jpeg",
    type: "image/jpeg",
  },
  {
    name: "png",
    type: "image/png",
  },
];

export default async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5,
  );

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y,
  );

  // As Base64 string
  return canvas.toDataURL("image/jpeg");

  //return canvas;
}

export const generateDownload = async (imageSrc, crop) => {
  if (!crop || !imageSrc) {
    return;
  }

  const canvas = await getCroppedImg(imageSrc, crop);

  return canvas;
};

export const isFileValid = (file, mimeTypes, maxSize, formatedSize) => {
  if (maxSize) {
    if (file.size > maxSize) {
      if (!formatedSize) {
        formatedSize = (size) => `${size} bytes`;
      }
      return {
        status: false,
        message: `File size should be less then ${formatedSize(maxSize)}.`,
      };
    }
  }

  if (mimeTypes && mimeTypes.length > 0) {
    const type = mimeTypes.find(({ type }) => type === file.type);
    if (!type) {
      return {
        status: false,
        message: `Only ${mimeTypes
          .map(({ name }, index) => {
            if (index !== 0 && index === mimeTypes.length - 1) {
              return " and " + name;
            }
            if (index === 0) {
              return name;
            }
            return ", " + name;
          })
          .join("")} are allowed.`,
      };
    }
  }
  return {
    status: true,
    message: "file is valid",
  };
};

export const useQuery = () => {
  const location = useLocation();

  let query = null;

  if (location.pathname && location.pathname.split("?")[1])
    query = new URLSearchParams("?" + location.pathname.split("?")[1]);

  if (!query) query = new URLSearchParams(location.search);

  return query;
};

export const UTILITIES = {
  notHaveWhitespaceOnEnds: (value = "") => {
    if (typeof value === "object") {
      value = value?.value?.description || "";
    }
    return !(value.startsWith(" ") || value.endsWith(" "));
  },
  removeEmptyStringKeys: (obj) => {
    const result = {};
    Object.entries(obj).forEach(([key, value]) => {
      if (value !== "") {
        result[key] = value;
      }
    });
    return result;
  },

  removeUndefinedOrNullKeys: (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === undefined || obj[key] === null) {
        delete obj[key];
      }
    });
    return obj;
  },

  get_DATE_WITHOUT_TIMEZONE: (date) => {
    // Here date is data object
    let momentObject = moment(date);
    let offsetInMinutes2 = momentObject.utcOffset();
    momentObject.utc().add(offsetInMinutes2, "m");
    return momentObject.toISOString();
  },

  showAmount: (amount) => {
    return parseInt(amount)
      .toString()
      .split(/(?=(?:\d{3})+(?:\.|$))/g)
      .join(",");
  },

  calculateProgress: (data = [], totalCount = 0) => {
    return Math.ceil((data?.length / totalCount) * 100);
  },

  dateToFromNowDaily: (myDate) => {
    // get from-now for this date
    let fromNow = moment(myDate).fromNow();

    // ensure the date is displayed with today and yesterday
    return moment(myDate).calendar(null, {
      // when the date is closer, specify custom values
      lastWeek: "[Last] dddd",
      lastDay: "[Yesterday]",
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      // when the date is further away, use from-now functionality
      sameElse: function () {
        return "[" + fromNow + "]";
      },
    });
  },
  voidFunction: () => {},
  whiteSpaceErrorHandler: (value) => {
    return !String(value) || String(value).trim() !== "";
  },

  REGEX: {
    EMAIL:
      /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    PASSWORD:
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,/:;<=>?@[\]^_`{|}~÷°¬±µ‰¤ƒ¥€£¢ß¿¡©®™§†‡—¶])(?=.{8,})/,
    PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{1,6}$/,
    WEBSITE_URL:
      /^(?:https?:\/\/)?(?:www\.)?[\w.-]+\.[a-z]{2,}(?:\/[\w.-]*)*\/?$/i,
    IS_NUMBER: /^[^0-9]+$/,
    IS_NUMBER_WITH_DECIMAL: /^[0-9]*\.?[0-9]*$/,
    ONLY_ALPHABET_AND_SPACE: /^[a-zA-Z ]+$/,
    PASSWORD_NEW:
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.@$!%*?&#])[A-Za-z\d@.$!%*#?&]{8,}$/,
    INVALID_NUMBER: /^[^+\-e=]*$/,
  },
};
