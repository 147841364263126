// constants
import { FOOTER_TAB, Images, STRINGS } from "Shared";

// libs
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// styles
import "./footer.scss";

const Footer = () => {
  const navigate = useNavigate();

  const handleClick = (data) => {
    if (data.label !== STRINGS.CHAT_WITH_US) {
      navigate({ pathname: data.path });
      return;
    }

    window.open(process.env.REACT_APP_API_CLIENT_CHAT_US_URL, "_self");
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const FooterIcons = [
    {
      icon: Images.FacebookIcon,
      link: "https://www.facebook.com/miniheartsfoundation?mibextid=LQQJ4d"
    },
    {
      icon: Images.InstaIcon,
      link: "https://instagram.com/miniheartsfoundation?igshid=MWZjMTM2ODFkZg=="
    },
    {
      icon: Images.XIcon,
      link: "https://twitter.com"
    },
    {
      icon: Images.LinkedinIcon,
      link: "https://www.linkedin.com/company/mini-hearts-foundation/"
    }
  ];

  return (
    <>
      {/* footer started  */}
      <footer>
        <div className="container">
          <div className="footer_items">
            <ul className="list-unstyled footer_links">
              {FOOTER_TAB.map((item, index) => (
                <li
                  key={index}
                  onClick={() => handleClick(item)}
                >
                  {item.label}
                </li>
              ))}
            </ul>

            <div className="social_footer">
              <ul className="list-unstyled">
                {FooterIcons.map((iconData) => (
                  <Link
                    to={iconData.link}
                    targrt="_blank"
                    rel="noopener noreferrer"
                  >
                    <em>
                      <img
                        src={iconData.icon}
                        alt=""
                        width="30"
                        height={30}
                      />
                    </em>
                  </Link>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
