import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "./style.scss";
const ReactPhoneInput = ({
  onChange,
  value,
  country = "au",
  onBlur,
  className,
  name,
  removeBordre = false,
}) => {
  return (
    <>
      <div className={`${removeBordre ? "disableClickClass" : ""}`}>
        <PhoneInput
          onBlur={onBlur}
          value={value}
          country={country}
          preferredCountries={["in", "us", "au", "in"]}
          countryCodeEditable={false}
          inputClass={`${className} phoneInputReact`}
          name={name}
          defaultMask={"... ... ... ... ..."}
          alwaysDefaultMask={true}
          onChange={onChange}
          inputStyle={{
            ...(removeBordre && {
              border: 0,
            }),
          }}
          disableDropdown={removeBordre ? true : false}
        />
      </div>
    </>
  );
};

export default ReactPhoneInput;
