import React from "react";
import "./style.scss";
import { Images } from "Shared";

const SubHeader = ({ heading, imgUrl, isFooter = false }) => {
  return (
    <>
      <div className="container-fluid  sub-header">
        <div className="container">
          {!isFooter ? (
            <>
              <div className="d-flex w-100 justify-content-md-between justify-content-center flex-wrap align-items-center  mx-auto">
                <h2 className="common-heading-subheader h1">
                  {heading}
                  <span>
                    <img
                      src={Images.pawWhite}
                      alt="Paw"
                      width={53}
                      height={70}
                    />
                  </span>
                </h2>
                <em>
                  <img
                    width=""
                    height=""
                    className="img-fluid"
                    src={imgUrl}
                    alt="img_url"
                  />
                </em>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex w-100 justify-content-center align-items-center  mx-auto">
                <h2 className="common-heading-subheader h1">{heading}</h2>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SubHeader;
