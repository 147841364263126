// libs
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";

// utils
import { UTILITIES } from "Shared/Utilities";
// actions
import { getChildCareProgram } from "Redux/Actions/CareProgram";

// components
import CustomSleekSliderChildProgram from "Components/Atom/CustomSleekSliderChildProgram";
import ChildStoryCard from "Components/Atom/ChildStoryCard";

const CareProgramChild = ({ enableLoader = true }) => {
  const dispatch = useDispatch();
  const limitRef = useRef(50);
  const skipRef = useRef(0);
  const [childData, setChildData] = useState({ data: [] });

  const fetchChildProgram = () => {
    dispatch(
      getChildCareProgram({
        limit: limitRef.current,
        skip: skipRef.current,
        success: (data) => {
          setChildData({
            data: [...(data?.childPost || [])],
            count: data?.totalCount,
          });
        },
        enableLoader,
      })
    );
  };

  useEffect(() => {
    fetchChildProgram();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {childData.data?.length ? (
        <CustomSleekSliderChildProgram
          isInfinite
          onPageChange={UTILITIES.voidFunction}
        >
          {childData?.data?.map((item, ind) => {
            return (
              <>
                <ChildStoryCard key={`index_${item?.id}`} item={item} />
              </>
            );
          })}
        </CustomSleekSliderChildProgram>
      ) : null}
    </>
  );
};

export default CareProgramChild;
