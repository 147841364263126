import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.scss";

const CustomReactDatePicker = ({
  placeholder,
  showDisabledMonthNavigation,
  value,
  showYearDropdown,
  className,
  name,
  minDate,
  maxDate,
  showMonthYearPicker = false,
  setDateValue = () => {},
}) => {
  return (
    <>
      <div className="datePickerBox">
        <DatePicker
          autoComplete="off"
          name={name}
          isClearable
          minDate={minDate}
          maxDate={maxDate}
          selected={value}
          showMonthYearPicker={showMonthYearPicker}
          showDisabledMonthNavigation={showDisabledMonthNavigation}
          showYearDropdown={showYearDropdown}
          onChange={setDateValue}
          className={`form-control custom_datePicker ${className || ""}`} // typing area
          placeholderText={placeholder || "Select date"}
          popperClassName="custom_datePicker" // popper class added successfully
          dateFormat="dd/MM/yyyy"
          dateFormatCalendar="MMMM"
          scrollableYearDropdown
          showMonthDropdown
          dropdownMode="select"
        />
      </div>
    </>
  );
};

export default CustomReactDatePicker;
