import moment from "moment";
import { ROUTE_CONSTANTS } from "./Routes";

export const GIVE_2_THE_KIDS_DOMAIN =
  process.env.REACT_APP_DOMAIN_GIVE2_THE_KIDS;
export const LIVE_CHAT_LICENSE_NUMBER =
  process.env.REACT_APP_API_LIVECHAT_LICENSE_NUMBER;

export const MAJOR_PARTNER_VIDEO_URL =
  "https://assets.minihearts.org/minihearts_promotional_video.mp4";

export const REDIRECTION_LINKS_OF_SPONSORS = {
  GIFTA: "https://giftagiftcards.com.au/",
  HYPE_SOCIETY: "https://www.thehypesociety.com.au/",
  GRAND_MASTER: "https://www.grandmasterdistillery.com.au",
  SALVO: "https://www.salvo.net.au",
  CREMA_GROUP: "https://www.crema.com.au/",
  HICTORY: "https://www.hickory.com.au/",
  THE_HARDWARE_CLUB: "https://thehardwareclub.com/",
  BARJAYDA: "https://www.barjayda.com.au",
  KAPITOL: "https://www.kapitolgroup.com.au/",
  LTE: "http://lteconstructiongroup.com.au",
  MAPEL: "https://www.mapei.com/au/en/home-page",
  JPS: "https://www.jpshealthandfitness.com.au/",
  ASG: "https://www.asgpropertypartners.com",
  SLF: "https://slflawyers.com.au/"
};

export const STORY_DESC_CHAR_LIMIT = 130;

export const DATE_FORMATS = {
  LONG_FORMAT_TIME_FIRST: "h:mm A, MMMM Do YYYY",
};

export const STRINGS = {
  MEDIA: "media",
  SOMETHING_WENT_WRONG: "Sorry, something went wrong.",
  OFFLINE_MESSAGE:
    "You appear to be offline. Please check your internet connection.",
  CHAT_WITH_US: "Chat with us",
  PRIVACY_POLICY: "Privacy Policy",
  TERMS_AND_CONDITION: "Terms & Conditions",
  MAJOR_PARTNER_DESC_PART_1: "Thankyou to our Major Sponsor",
  MAJOR_PARTNER_DESC_PART_2:
    "who with every single donation you make directly to Mini Hearts,",
  MAJOR_PARTNER_DESC_PART_3:
    "will provide you with FREE tickets to this amazing $50,000.00 showcase giveaway consisting of over 45 prizes ranging from $250.00 - $5,000.00, with the top prize being $25,000.00 cash.",
  GIVE_2_THE_KIDS: "Give2theKids",
  THANKS_SPONSORS: (
    <>
      Thanks to our major sponsors
      <strong> give2thekids </strong>
      and
      <strong> gifta </strong>, this
      <strong className="text-primary"> $50,000.00 </strong>
      showcase of prizes can be won and used at most of the major well-known
      retailers throughout Australia.
    </>
  ),
  FORMAT_FOR_LAST_REVIEW: (
    <>
      Last reviewed and updated on{" "}
      {moment().format(DATE_FORMATS.LONG_FORMAT_TIME_FIRST)}
    </>
  ),
  PRIVACY_POLICY_TEXT_1: (
    <>
      Mini Hearts Foundation values and respects the privacy of the people we
      deal with. Mini Hearts Foundation is committed to protecting your privacy
      and complying with the Privacy Act 1988 (Cth) (Privacy Act) and other
      applicable privacy laws and regulations.
    </>
  ),
  PRIVACY_POLICY_TEXT_2: (
    <>
      This Privacy Policy <strong>(Policy) </strong>describes how we collect,
      hold, use and disclose your personal information, and how we maintain the
      quality and security of your personal information.
    </>
  ),
  PRIVACY_POLICY_TEXT_3: (
    <>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis in vel
      beatae porro, harum voluptate odit magni, exercitationem laboriosam
      dolorum ullam maxime iste odio unde dolore totam ipsum ex dicta debitis.
      Assumenda sunt ut harum ullam iure enim aliquam quisquam laborum adipisci
      animi fugit deleniti, dolor sint itaque suscipit error nostrum ea
      similique quam nesciunt. Deleniti possimus ratione consequatur facere
      necessitatibus eaque repellat error maxime aperiam, ea non nulla animi
      sapiente vel nisi blanditiis. Aliquam cumque exercitationem magni
      praesentium debitis dolore, atque laboriosam officiis. Dolor, quia
      consequuntur a voluptates repudiandae maiores fugit aperiam culpa
      doloremque voluptas! Omnis eaque unde dolorem eligendi quis corrupti
      officiis tempora eum quod dolor reprehenderit commodi repellat, porro ex
      ab ratione ea! Dolores, eum voluptates ipsum perspiciatis temporibus
      veritatis enim ut nulla debitis a esse dolore iste eveniet doloremque ipsa
      illum quaerat recusandae. Magni autem fugit reiciendis praesentium non
      explicabo enim, incidunt dolorum iure modi porro fuga maiores rem
      asperiores omnis! Iste, quaerat laboriosam, fugit consequuntur, quidem rem
      magnam quis temporibus beatae assumenda voluptas sed fuga sit distinctio
      quasi deleniti eaque id et molestiae cum delectus perferendis odio iusto
      repudiandae. Ullam, odio! Perspiciatis neque vero fugit rem atque? Unde
      modi debitis, laboriosam placeat consectetur minus repellendus facere
      necessitatibus perferendis distinctio libero. Quae alias vitae error,
      quasi recusandae necessitatibus sed voluptatem laborum, dignissimos labore
      ea dolor adipisci magnam dicta atque dolore architecto quis hic nisi nam
      facere deleniti explicabo veritatis aperiam! Necessitatibus officia, omnis
      quibusdam explicabo qui illum eaque pariatur iusto aperiam fugiat saepe
      mollitia vitae consequuntur?
    </>
  ),
  PRIVACY_POLICY_TEXT_4: (
    <>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis in vel
      beatae porro, harum voluptate odit magni, exercitationem laboriosam
      dolorum ullam maxime iste odio unde dolore totam ipsum ex dicta debitis.
      Assumenda sunt ut harum ullam iure enim aliquam quisquam laborum adipisci
      animi fugit deleniti, dolor sint itaque suscipit error nostrum ea
      similique quam nesciunt. Deleniti possimus ratione consequatur facere
      necessitatibus eaque repellat error maxime aperiam, ea non nulla animi
      sapiente vel nisi blanditiis. Aliquam cumque exercitationem magni
      praesentium debitis dolore, atque laboriosam officiis. Dolor, quia
      consequuntur a voluptates repudiandae maiores fugit aperiam culpa
      doloremque voluptas! Omnis eaque unde dolorem eligendi quis corrupti
      officiis tempora eum quod dolor reprehenderit commodi repellat, porro ex
      ab ratione ea! Dolores, eum voluptates ipsum perspiciatis temporibus
      veritatis enim ut nulla debitis a esse dolore iste eveniet doloremque ipsa
      illum quaerat recusandae. Magni autem fugit reiciendis praesentium non
      explicabo enim, incidunt dolorum iure modi porro fuga maiores rem
      asperiores omnis! Iste, quaerat laboriosam, fugit consequuntur, quidem rem
      magnam quis temporibus beatae assumenda voluptas sed fuga sit distinctio
      quasi deleniti eaque id et molestiae cum delectus perferendis odio iusto
      repudiandae. Ullam, odio! Perspiciatis neque vero fugit rem atque? Unde
      modi debitis, laboriosam placeat consectetur minus repellendus facere
      necessitatibus perferendis distinctio libero. Quae alias vitae error,
      quasi recusandae necessitatibus sed voluptatem laborum, dignissimos labore
      ea dolor adipisci magnam dicta atque dolore architecto quis hic nisi nam
      facere deleniti explicabo veritatis aperiam! Necessitatibus officia, omnis
      quibusdam explicabo qui illum eaque pariatur iusto aperiam fugiat saepe
      mollitia vitae consequuntur?
    </>
  ),
  PRIVACY_POLICY: {
    EMAIL: {
      LABEL: "Email",
      ADDRESS: "hello@minihearts.org",
    },
    CONTACT: {
      LABEL: "Contact Number",
      NUMBER: "+61 3 9221 6130",
    },
    POST: {
      LABEL: "Post",
      VALUE_1: "Attention:",
      VALUE_2: "Mini Hearts Secretary",
    },
    ADDRESS: {
      LABEL: "Address:",
      VALUE_1: " Level 27 / 101 Collins St",
      VALUE_2: "Melbourne, Victoria, Australia",
      VALUE_3: "3000",
    },
    TITLE: "Privacy Policy",
    PARA_1: (
      <>
        Mini Hearts Foundation values and respects the privacy of the people we
        deal with. Mini Hearts Foundation is committed to protecting your
        privacy and complying with the Privacy Act 1988 (Cth) (Privacy Act) and
        other applicable privacy laws and regulations.
      </>
    ),
    PARA_2: (
      <>
        This Privacy Policy <strong>(Policy) </strong>describes how we collect,
        hold, use and disclose your personal information, and how we maintain
        the quality and security of your personal information.
      </>
    ),
    PARA_3: (
      <>
        “Personal information” means any information or opinion, whether true or
        not, and whether recorded in a material form or not, about an identified
        individual or an individual who is reasonably identifiable. In general
        terms, this includes information or an opinion that personally
        identifies you either directly (e.g. your name) or indirectly.
      </>
    ),
    PARA_4: `
        The personal information we collect about you depends on the nature of
        your dealings with us or what you choose to share with us.
      `,
    PARA_5: "The personal information we collect about you may include:",
    PARA_6: `iUnder certain circumstances, Mini Hearts Foundation may need to
    collect sensitive information about you. This might include any
    information or opinion about your racial or ethnic origin,
    political opinions, political association, religious or
    philosophical beliefs, membership of a trade union or other
    professional body, sexual preferences, criminal record, or health
    information.`,
    PARA_7: ` If we collect your sensitive information, we will do so only with
    your consent, if it is necessary to prevent a serious and imminent
    threat to life or health, or as otherwise required or authorised
    by law, and we take appropriate measures to protect the security
    of this information.`,
    PARA_8: `You do not have to provide us with your personal information.
    Where possible, we will give you the option to interact with us
    anonymously or by using a pseudonym. However, if you choose to
    deal with us in this way or choose not to provide us with your
    personal information, we may not be able to provide you with our
    services or otherwise interact with you.`,
    PARA_9: `  We collect your personal information directly from you when you:`,
    PARA_10: `We use personal information for many purposes in connection with
    our functions and activities, including the following purposes:`,
    PARA_11: `We may disclose your personal information to third parties in
    accordance with this Policy in circumstances where you would
    reasonably expect us to disclose your information. For example, we
    may disclose your personal information to:`,
    PARA_12: `Some of the third-party service providers we disclose personal
    information to may be based in or have servers located outside of
    Australia.`,
    PARA_13: `  Where we disclose your personal information to third parties
    overseas, we will take reasonable steps to ensure that data
    security and appropriate privacy practices are maintained. We will
    only disclose to overseas third parties if:`,
    PARA_14: `Mini Hearts Foundation will take reasonable steps to ensure that
    the personal information that we hold about you is kept
    confidential and secure, including by:`,
    PARA_15: `The Mini Hearts Foundation website uses cookies. A cookie is a
    small file of letters and numbers the website puts on your device
    if you allow it. These cookies recognise when your device has
    visited our website(s) before, so we can distinguish you from
    other users of the website. This improves your experience and the
    Mini Hearts Foundation website(s).`,
    PARA_16: ` We do not use cookies to identify you, just to improve your
    experience on our website(s). If you do not wish to use the
    cookies, you can amend the settings on your internet browser so it
    will not automatically download cookies. However, if you remove or
    block cookies on your computer, please be aware that your browsing
    experience and our website’s functionality may be affected.`,
    PARA_17: ` Our website uses Google analytics to help us better understand
    visitor traffic, so we can improve our services. Although this
    data is mostly anonymous, it is possible that under certain
    circumstances, we may connect it to you.`,
    PARA_18: `We may send you direct marketing communications and information
    about our services, opportunities, or events that we consider may
    be of interest to you if you have requested or consented to
    receive such communications. These communications may be sent in
    various forms, including mail, SMS, fax and email, in accordance
    with applicable marketing laws, such as the Australian Spam Act
    2003 (Cth). You consent to us sending you those direct marketing
    communications by any of those methods. If you indicate a
    preference for a method of communication, we will endeavour to use
    that method whenever practical to do so.`,
    PARA_19: ` You may opt-out of receiving marketing communications from us at
    any time by following the instructions to “unsubscribe'' set out
    in the relevant communication or by contacting us using the
    details set out in the “How to contact us” section below. In
    addition, we may also use your personal information or disclose
    your personal information to third parties for the purposes of
    advertising, including online behavioural advertising, website
    personalisation, and to provide targeted or retargeted advertising
    content to you (including through third party websites).`,
    PARA_20: ` We will not keep your personal information for longer than we need
    to. In most cases, this means that we will only retain your
    personal information for the duration of your relationship with us
    unless we are required to retain your personal information to
    comply with applicable laws, for example record-keeping
    obligations.`,
    PARA_21: `Mini Hearts Foundation will endeavour to keep your personal
    information accurate, complete and up to date.`,
    PARA_22: `If you wish to make a request to access and / or correct the
    personal information we hold about you, you should make a request
    by contacting us and we will usually respond within 14 days. We
    will deal with such a request by following the procedure outlined
    below:`,
    PARA_23: ` Mini Hearts Foundation website(s) may contain links to websites
    operated by third parties. If you access a third party website
    through our website(s), personal information may be collected by
    that third party website. We make no representations or warranties
    in relation to the privacy practices of any third party provider
    or website and we are not responsible for the privacy policies or
    the content of any third party provider or website. Third party
    providers / websites are responsible for informing you about their
    own privacy practices and we encourage you to read their privacy
    policies.`,
    PARA_24: `If you have a question or concern in relation to our handling of
    your personal information or this Policy, you can contact us for
    assistance as follows:`,
    HEADING_1: "What is personal information?",
    HEADING_2: "What personal information do we collect?",
    HEADING_3: "How do we collect your personal information?",
    HEADING_4: "How do we use your personal information?",
    HEADING_5: "Disclosure of personal information to third parties",
    HEADING_6: "Transfer of personal information overseas",
    HEADING_7: "How do we protect your personal information?",
    HEADING_8: "Website analytics",
    HEADING_9: "Direct marketing",
    HEADING_10: "Retention of personal information",
    HEADING_11: "How to access and correct your personal information",
    HEADING_12: "Links to third party sites",
    HEADING_13: "Online activity",
    HEADING_14: "Cookies",
    HEADING_15: "Inquiries and complaints",
    HEADING_16: "How to contact us",
    UNORDER_LIST: {
      FIRST: {
        LIST: {
          FIRST: "name;",
          SECOND: "mailing or street address;",
          THIRD: "date of birth;",
          FOURTH: "email address;",
          FIFTH: "phone number;",
          SIX: "age;",
        },
      },
      SECOND: {
        LIST: {
          FIRST: "interact with us over the phone;",
          SECOND: "interact with us in person;",
          THIRD: "interact with us online;",
          FOURTH: "participate in surveys, questionnaires or competitions;",
          FIFTH: "attend a Organisation event;",
          SIX: "subscribe to our mailing list;",
          SEVEN:
            " apply for a position with us as an employee, contractor or volunteer;",
        },
      },
      THIRD: {
        LIST: {
          FIRST:
            "  provide you with information or services that you request from us;",
          SECOND:
            "deliver to you a more personalised experience and service offering;",
          THIRD: "improve the quality of the services we offer;",
          FOURTH: "internal administrative purposes;",
          FIFTH: "marketing and research purposes;",
        },
      },
      FOURTH: {
        LIST: {
          FIRST:
            " our third party service providers (for example, our IT providers);",
          SECOND: "our marketing providers;",
          THIRD: "our professional services advisors",
        },
      },
      FIFTH: {
        LIST: {
          FIRST:
            "you have given us your consent to disclose personal information to that third party; or",
          SECOND: {
            PARA_1: "we reasonably believe that:",
            UNORDER_LIST: {
              LIST_ONE:
                " the overseas recipient is subject to a law or binding scheme that is, overall, substantially similar to the APPs; and",
              LIST_TWO: "the law or binding scheme can be enforced; or",
            },
          },
          THIRD:
            "the disclosure is required or authorised by an Australian law or court / tribunal order.",
        },
      },
      SIX: {
        LIST: {
          FIRST:
            "   having a robust physical security of our premises and databases / records;",
          SECOND:
            " taking measures to restrict access to only personnel who need that personal information to effectively provide services to you;",
          THIRD:
            " having technological measures in place (for example, anti-virus software, fire walls);",
        },
      },
      SEVEN: {
        LIST: {
          FIRST: "accept receipt of your email",
          SECOND: "process your email",
          THIRD: "reply to your email with the relevant information",
        },
      },
      EIGHT: {
        LIST: {
          FIRST: "accept receipt of your email",
          SECOND: "process your email",
          THIRD: "reply to your email with the relevant information",
        },
      },
    },
  },
};

export const ERROR_MESSAGE = {
  VALID_EMAIL: "Enter a valid email",
  NUMBER_NOT_ALLOWED: "Number is not allowed",
  WHITE_SPACE_NOT_ALLOWED: "Whitespace is not allowed",
  MAX_CHARACTER_COUNT_EXCEEDED: "Maximum character count exceeded",
};

export const STRING_NUMBER = {
  NA: "NA",
  ZERO: "0",
  ONE: "1",
  TWO: "2",
  THREE: "3",
  FOUR: "4",
  FIVE: "5",
  SIX: "6",
  SEVEN: "7",
  EIGHT: "8",
  NINE: "9",
  TEN: "10",
  ELEVEN: "11",
  TWELVE: "12",
  THIRTEEN: "13",
  FOURTEEN: "14",
  FIFTEEN: "15"
};

export const ERROR_STRING = {
  FIELD_REQUIRED: "Field is required",
};

export const FOOTER_TAB = [
  {
    label: "Terms & Conditions",
    value: "2",
    path: ROUTE_CONSTANTS.TERM_AND_CONDITIONS,
  },
  {
    label: "Privacy Policy",
    value: "3",
    path: ROUTE_CONSTANTS.PRIVACY_POLICY_PAGE,
  },
  {
    label: "Contact Us",
    value: "3",
    path: ROUTE_CONSTANTS.CONTACT_US,
  },
];

export const LABELS = {
  PRIVACY_POLICY: "Privacy Policy",
  WE_CARE_PROGRAM: " We Care Program - Apply Now",
  HOME: "Home",
  APPLY_NOW: "Apply Now",
  STORY_BOARD: "Story Board",
  TERMS_AND_CONDITIONS: " Terms & Conditions",
  TERMS_AND_CONDITIONS_HEADING: " Terms & Conditions",
  CONTACT_US: "Contact Us",
  BECOME_MEMBER: " Become A Member / Login",
  MINI_HEARTS_MESSAGE: (
    <>
      The Mini Hearts <strong>We Care Program </strong> has been designed to
      help individuals on a weekly, fortnightly or monthly basis that are in
      need of support through the hard times. Depending on your circumstances
      and your application, the Mini Hearts board meet once a week to review all
      applications and make a determination as to who is in most need and how we
      can help in the immediate future. The program is designed to help kids
      between the ages of 0-16 and it is up to the discretion of Mini Hearts as
      to who is accepted into the program. <br />
      Weekly payments can range from $100 - $500 p/week.
    </>
  ),
  MINI_HEARTS_MESSAGE_II:
    "Every donation enables us to support more children in need and grow this unique and powerful initiative",
  FIRST_NAME: "First Name",
  YOUR_SURNAME: "Surname",
  EMAIL: "Email",
  CHILD_NAME: "Child Name",
  CHILD_SURNAME: "Child Surname",
  DOB: "DOB",
  DESCRIPTION_CHILD: "Description of Childs Condition",
  SUBMIT: "Submit",
  MOBILE_NUMBER: "Mobile Number",
  CARE_PROGRAM: "We Care Program",
  CHILD_DOB: "Child D.O.B",
  UPLOAD: "Upload photo of child",
  UPLOAD_SUCCESS: "Upload Successfully",
  INSPIRATION_ASSIS_COM: "Inspiration , assistance and Community",
  INSPIRATION_MESSAGE:
    "Our Dedicated team offers compassion and support alongside financial assistance to ensure that each child knows they are cared for during this difficult time. With your help, we can continue providing our heart-warming services so we can make a difference in the life of every child who needs us.",
  GLOBAL_ORGANISATION: "Global Charitable Organisation",
  DIVERSITY: "Diversity and Inclusion",
  FOCUSSED_GOAL: "Focussed Goals",
  EFFICIENT_TECHNOLOGY: "Efficient Technology",
  EXPERIENCED_MANAGEMENT: "Experienced Management",
  FOCUSSED_GOAL_DESC: "Mini Hearts Helps Children Between 0-16",
  EFFICIENT_TECHNOLOGY_DESC: "Investments in technology to minimise expenses",
  EXPERIENCED_MANAGEMENT_DESC:
    "A wide range of experience on the management team",
  MAIN_PARTNER: "Our Main Sponsors",
  MAIN_PARTNER_DESC:
    "Our innovative platform allows more donations to get to the places that are needed within our community.",
  MAJOR_PARNTER: "Major Sponsor- Give2TheKids",
  SUPPORT_COMMUNITY: "How your donations are distributed",
  SUPPORT_COMMUNITY_2:
    "funds raised distributed directly to children with long term health conditions",
  CHILDREN_CONCERNS: "Children with long terms conditions",
  HEALTH:
    "Donate directly to your selected child in need through this great initiative",
  TOWARDS_CHILDREN:
    "funds raised and directly distributed to children 0-16yrs old",
  STAFF_AND_VOLUNTEERS: "Dedicated staff and volunteers",
  THANK_YOU: "Thank You",
  REGARDS: "Regards",
  MINI_HEARTS_MANAGEMENT: "Mini Hearts Management",
  PARAGRAPH: `Thank You for applying for the Mini Hearts "We Care Program". The Board will review your application over the next 14 days and will come back to you if your application is successful.`,
  EDIT_PHOTO: "Edit Photo",
  PRIZE_POOL: "Prize Pool",
};

export const IMAGE_SIZES = {
  BIG: 250,
  VERY_BIG: 400,
  LARGE: 500,
  VERY_SMALL: 20,
};
