import React from "react";
import { LiveChatWidget } from "@livechat/widget-react";
import { LIVE_CHAT_LICENSE_NUMBER } from "Shared";

const MyChatComponent = () => {
  return (
    <div>
      {/* Your other content here */}
      <LiveChatWidget
        license={LIVE_CHAT_LICENSE_NUMBER}
        visibility="minimized"
      />
    </div>
  );
};

export default MyChatComponent;
