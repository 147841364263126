// libs
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { SnackbarProvider } from "notistack";

// store
import { store, persistor } from "./Redux/Store";

// components
import RootRouter from "./Routes/RootRouter";

// styles
import "./App.scss";
import { useEffect } from "react";
import { SnackbarUtilsConfigurator } from "Shared/SnackbarUtilConfig";
import Loader from "Components/Core/Loader";
import MyChatComponent from "Components/Atom/MyChatComponent";

function App() {
  return (
    // UPDATE: I was able to get this working again... Enjoy!

    <>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={2000}
        anchorOrigin={{ horizontal: "top", vertical: "center" }}
        className="mt-5"
      >
        <Provider store={store}>
          <SnackbarUtilsConfigurator />
          <Loader />
          <MyChatComponent />
          <PersistGate persistor={persistor}>
            <RootRouter />
          </PersistGate>
        </Provider>
      </SnackbarProvider>
    </>
  );
}

export default App;
