// libs
import { useEffect, useRef } from "react";

// components
import BenefitProgram from "./Components/BenefitProgram";
import ImageStory from "./Components/ImageStory";
import InsipirationCommunity from "./Components/InsipirationCommunity";
import MainPartner from "./Components/MainPartner";
import SupportCommunity from "./Components/SupportCommunity";
import Testimonials from "./Components/Testimonials";

const SinglePage = () => {
  const transitionRef = useRef(1);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <ImageStory />
      <BenefitProgram />
      <section className="section major_testimonial_secn">
        <div className="container">
          <div className="row">
            <div
              className="col-md-12 col-lg-12  wow fadeInUp"
              data-wow-duration={`0.${transitionRef.current + 1}s`}
              data-wow-delay={`0.${transitionRef.current + 1}s`}
            >
              <Testimonials />
            </div>
            {/* <div
              className="col-md-12 col-lg-6  wow fadeInUp"
              data-wow-duration={`0.${transitionRef.current + 3}s`}
              data-wow-delay={`0.${transitionRef.current + 3}s`}
            >
              <MajorPartner />
            </div> */}
          </div>
        </div>
      </section>

      <SupportCommunity />
      <MainPartner />

      <InsipirationCommunity />
    </>
  );
};

export default SinglePage;
