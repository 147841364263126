// components
import ReactPhoneInput from "Components/Atom/ReactPhoneInput";
import TextField from "Components/Atom/TextField";

// constants
import { ERROR_STRING, Images, STRINGS, Snackbar } from "Shared";

// libs
import { ErrorMessage, Field, Form, Formik } from "formik";
import { UTILITIES } from "Shared/Utilities";
import { useDispatch } from "react-redux";
import React, { useRef, useState } from "react";
import * as yup from "yup";
import { contactAction } from "Redux/Actions/Contact";

const validationHandler = yup.object({
  username: yup.string().trim().required(ERROR_STRING.FIELD_REQUIRED),
  subject: yup.string().trim().required(ERROR_STRING.FIELD_REQUIRED),
  message: yup.string().trim().required(ERROR_STRING.FIELD_REQUIRED),
  email: yup
    .string()
    .trim()
    .email("Enter a valid email")
    .required("Email is required"),
  phone: yup
    .string()
    .trim()
    .required("Field is required")
    .test(
      "noWhitespace",
      "Whitespace is not allowed",
      UTILITIES.whiteSpaceErrorHandler,
    )
    .max(25, "Maximum character count exceeded")
    .min(9, "Phone number must contain atleast 7 numbers"),
});

const initialValues = {
  username: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
};

const MessageForm = () => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);

  const [isChatOpen, setIsChatOpen] = useState(false);

  const handleSubmit = (data) => {
    let formData = {
      portal: 2,
      email: data?.email,
      phone: data?.phone,
      subject: data?.subject,
      yourName: data?.username,
      message: data?.message,
    };

    dispatch(
      contactAction({
        formData: formData,
        success: (successMsg) => {
          let msg = successMsg || "Success";
          Snackbar.success(msg);
          formikRef.current.resetForm();
          formikRef.current.setFieldValue("phone", "+61");
        },
        fail: (err) => {
          let errMsg = err || STRINGS.ERROR_STRING.SOMETHING_WENT_WRONG;
          Snackbar.error(errMsg);
          formikRef.current.resetForm();
          formikRef.current.setFieldValue("phone", "+61");
        },
      }),
    );
  };

  // Function to open the LiveChat widget
  function openLiveChat() {
    // Check if the LiveChat script is loaded
    if (!isChatOpen) {
      window.LC_API.open_mobile_window();
      setIsChatOpen(true);
      return;
    }
    debugger;
    setIsChatOpen(false);
    window.LC_API.close_chat();
  }

  return (
    <>
      <div className="card contactCard ">
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <div className="contact_title">
            <span className="text-uppercase">Have Questions?</span>
            <h2 className="h3">Send us a message</h2>
          </div>
          <div
            className="d-flex align-items-center live_chat my-cursor-pointer"
            onClick={openLiveChat}
          >
            <em>
              <img
                src={Images.LiveChat}
                alt="img_contact"
                width={42}
                height={37}
              />
            </em>
            Live Chat
          </div>
        </div>
        <div className="contact_form mt-4">
          <Formik
            innerRef={(e) => (formikRef.current = e)}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationHandler}
          >
            {({ errors, touched, setFieldValue, values, setFieldTouched }) => (
              <Form>
                <div className="row mt-3">
                  <div className="col-md-6 form-group">
                    <TextField
                      name={"username"}
                      placeholder={"Name"}
                      type="text"
                      className={`form-control ${
                        touched?.username && errors?.username
                          ? "invalid-error"
                          : ""
                      }`}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <TextField
                      name={"email"}
                      placeholder={"Email"}
                      type="text"
                      className={`form-control ${
                        touched?.email && errors?.email ? "invalid-error" : ""
                      }`}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <Field
                      name="phone"
                      type="tel"
                      value={values.phone}
                      component={ReactPhoneInput}
                      className={`${
                        touched?.phone && errors?.phone ? "invalid-error" : ""
                      }`}
                      placeholder={"Type your Phone number"}
                      onChange={(e) => {
                        let number_format = `+${e}`;
                        setFieldValue("phone", number_format);
                      }}
                    />
                    {errors?.phone ? (
                      <div className="error">
                        <ErrorMessage name="phone" />
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-6 form-group">
                    <TextField
                      name={"subject"}
                      placeholder={"Subject"}
                      type="text"
                      className={`form-control ${
                        touched?.subject && errors?.subject
                          ? "invalid-error"
                          : ""
                      }`}
                    />
                  </div>
                  <div className="col-md-12 form-group">
                    <textarea
                      name="message"
                      as="textarea"
                      id="message"
                      rows="3"
                      value={values?.message}
                      className={`form-control ${
                        touched?.message && errors?.message
                          ? "invalid-error"
                          : ""
                      }`}
                      placeholder={"Message"}
                      onChange={(e) => {
                        let { value } = e.target;
                        setFieldValue("message", value);
                        setFieldTouched("message", true);
                      }}
                    />
                    {errors?.message ? (
                      <div className="error">
                        <ErrorMessage name="message" />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12 text-center mt-md-4 mt-3">
                  <button
                    type="submit"
                    className="btn btn-md btn-primary mx-auto"
                  >
                    Chat Now
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default MessageForm;
