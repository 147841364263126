import MiniHeartLogo from "../Assets/Images/mini-heart_logo.svg";
import miniHeartFoundation from "../Assets/Images/miniHeartsFoundation.svg";

import editSvg from "../Assets/Images/icons/edit.svg";
import deleteSvg from "../Assets/Images/icons/delete.svg";
import donateIcon from "../Assets/Images/icons/donateIcon.svg";
import searchIcon from "../Assets/Images/icons/search-icon.svg";
import RedHeart from "../Assets/Images/payment/red-heart-shape.svg";
import SilverHeart from "../Assets/Images/payment/silver-heart-shape.svg";
import GoldHeart from "../Assets/Images/payment/gold-heart-shape.svg";
import DiamondHeart from "../Assets/Images/payment/diamond-heart-shape.svg";
import StripeImage from "../Assets/Images/payment/payment-stripe.svg";
import DonateNow from "../Assets/Images/donate-now.svg";
import arrowButton from "../Assets/Images/icons/arrow_bottom.svg";

import give_kids from "../Assets/Images/give_kids.png";
import give2_kids from "../Assets/Images/give_kids.png";
import banner_image from "../Assets/Images/main-image.jpg";
import shieldCheck from "../Assets/Images/icons/shield-check.svg";
import badgeSvg from "../Assets/Images/icons/badge.svg";
import CrossIcon from "../Assets/Images/icons/cross_icon.svg";
import CalenderIcon from "../Assets/Images/icons/calendar-clock.svg";
import TechnoIcon from "../Assets/Images/icons/technology_icon.svg";
import rubicon from "../Assets/Images/rubicon.jpg";
import efficient from "../Assets/Images/icons/efficient.svg";
import diversity from "../Assets/Images/icons/diversity.svg";
import experienced from "../Assets/Images/icons/experienced.svg";
import focussed from "../Assets/Images/icons/focused.svg";
import g2tkLogo from "../Assets/Images/G2TK-logo.jpg";
import mini_heart_yellow_girl from "../Assets/Images/mini-hearts-image.jpg";
import hardware_club from "../Assets/Images/thehardwareclub.jpg";
import SuccessImg from "../Assets/Images/payment/success-img.svg";
import Signature from "../Assets/Images/payment/signature.svg";
import gigtaJpeg from "../Assets/Images/gifta-logo.jpg";
import Gifta from "../Assets/Images/new_gifta_logo.svg";
import LiveChat from "../Assets/Images/icons/live_chat.svg";
import FacebookIcon from "../Assets/Images/icons/facebook.svg";
import InstaIcon from "../Assets/Images/icons/instagram.svg";
import XIcon from "../Assets/Images/icons/twitter.svg";
import LinkedinIcon from "../Assets/Images/icons/linkedin.svg";
import cash from "../Assets/Images/cash.svg";

// main partners
import CREMA_GROUP from "../Assets/Images/main-partners/crema-group.png";
import GIVE_2_THE_KIDS from "../Assets/Images/main-partners/give2_kids_logo.jpg";
import BARJAYDA from "../Assets/Images/main-partners/barjayda_logo.jpg";
import GIFTA from "../Assets/Images/main-partners/gifta_logo.jpg";
import GRAND_MASTER from "../Assets/Images/main-partners/grand_master_logo.jpg";
import HICKORY from "../Assets/Images/main-partners/hickroy_logo.jpg";
import HYPE_SOCIETY from "../Assets/Images/main-partners/hype_society_logo.jpg";
import KAPITOL_GROUP from "../Assets/Images/main-partners/kapitol_group_logo.jpg";
import LTE from "../Assets/Images/main-partners/lte_logo.jpg";
import MAPEL from "../Assets/Images/main-partners/mapel_logo.jpg";
import SALVO from "../Assets/Images/main-partners/salvo_logo.jpg";
import SLF from "../Assets/Images/main-partners/slf_logo.jpg";
import THE_HARDWARE_CLUB from "../Assets/Images/main-partners/hardware_logo.jpg";
import JPS from "../Assets/Images/main-partners/jps.png";
import ASG from "../Assets/Images/main-partners/asg.png";

// prize-pool
import AMERT from "../Assets/prize-pool/amert.png";
import APPLE from "../Assets/prize-pool/apple.png";
import BARBEQUES from "../Assets/prize-pool/barbeques.png";
import BCF from "../Assets/prize-pool/BCF.png";
import BIG_W from "../Assets/prize-pool/big_w.png";
import BP from "../Assets/prize-pool/bp.png";
import BUNNINGS from "../Assets/prize-pool/bunnings.png";
import BWS from "../Assets/prize-pool/bws.png";
import COUNTRY_ROAD from "../Assets/prize-pool/country_road.png";
import DAVID_JONES from "../Assets/prize-pool/david_jones.png";
import DOOR_DASH from "../Assets/prize-pool/door_dash.png";
import E_BAY_LOGO from "../Assets/prize-pool/ebay_logo.png";
import E_BAY from "../Assets/prize-pool/ebay.png";
import FIGHT_CENTER from "../Assets/prize-pool/fight_center.png";
import FOREVER from "../Assets/prize-pool/forever.png";
import FREEDOM from "../Assets/prize-pool/Freedom.png";
import HOYTS from "../Assets/prize-pool/hoyts.png";
import IKEA from "../Assets/prize-pool/ikea.png";
import JB from "../Assets/prize-pool/JB.png";
import JETSTAR from "../Assets/prize-pool/JETSTAR.png";
import KATHMANDU from "../Assets/prize-pool/kathmandu.png";
import LAYER from "../Assets/prize-pool/layer.png";
import MICHAEL_HILL from "../Assets/prize-pool/michael_hill.png";
import MIMCO from "../Assets/prize-pool/mimco.png";
import MYER from "../Assets/prize-pool/myer.png";
import OPSM from "../Assets/prize-pool/opsm.png";
import PANDORA from "../Assets/prize-pool/pandora.png";
import PRICE_PINE from "../Assets/prize-pool/Pricepine.png";
import STRAND_BAGS from "../Assets/prize-pool/Strandbags.png";
import GOOD_GUYS from "../Assets/prize-pool/the_good_guys.png";
import UBER_EATS from "../Assets/prize-pool/uber_eats.png";
import WEBJET from "../Assets/prize-pool/Webjet.png";
import WOOL_WORTHS from "../Assets/prize-pool/wool_worths.png";

//leadership team
import AmalDavis from "../Assets/Images/leadership/amal-davis.png";
import JustinDavis from "../Assets/Images/leadership/justin-davis.png";
import MarkGulifa from "../Assets/Images/leadership/mark-guifa.png";
import FrankMarc from "../Assets/Images/leadership/frank-marcaccio.png";
import Colin from "../Assets/Images/leadership/colin.png";
import LeeSmartino from "../Assets/Images/leadership/lee-smartino.png";

export const Images = {
  MiniHeartLogo,
  miniHeartFoundation,
  Gifta,
  FacebookIcon,
  LinkedinIcon,
  XIcon,
  InstaIcon,
  LiveChat,
  gigtaJpeg,
  SuccessImg,
  Signature,
  hardware_club,
  mini_heart_yellow_girl,
  g2tkLogo,
  efficient,
  diversity,
  experienced,
  focussed,
  rubicon,
  TechnoIcon,
  CalenderIcon,
  CrossIcon,
  badgeSvg,
  give_kids,
  give2_kids,
  editSvg,
  deleteSvg,

  donateIcon,
  searchIcon,
  RedHeart,
  SilverHeart,
  GoldHeart,
  DiamondHeart,
  StripeImage,
  DonateNow,
  arrowButton,
  cash,

  //leadership
  AmalDavis,
  JustinDavis,
  MarkGulifa,
  FrankMarc,
  Colin,
  LeeSmartino,

  banner_image,
  shieldCheck,
  main_partner: {
    GIVE_2_THE_KIDS,
    GIFTA,
    SLF,
    GRAND_MASTER,
    SALVO,
    CREMA_GROUP,
    HICKORY,
    THE_HARDWARE_CLUB,
    BARJAYDA,
    HYPE_SOCIETY,
    LTE,
    MAPEL,
    KAPITOL_GROUP,
    JPS,
    ASG,
  },
  prize_pool: [
    FREEDOM,
    WOOL_WORTHS,
    UBER_EATS,
    E_BAY_LOGO,
    WEBJET,
    GOOD_GUYS,
    STRAND_BAGS,
    PRICE_PINE,
    BCF,
    FIGHT_CENTER,
    JETSTAR,
    DAVID_JONES,
    BWS,
    FOREVER,
    KATHMANDU,
    BIG_W,
    MIMCO,
    COUNTRY_ROAD,
    BUNNINGS,
    IKEA,
    MICHAEL_HILL,
    APPLE,
    HOYTS,
    PANDORA,
    OPSM,
    MYER,
    JB,
    DOOR_DASH,
    E_BAY,
    BARBEQUES,
    BP,
    AMERT,
    LAYER,
  ],
};
