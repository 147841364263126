// libs
import React, { useState, useRef, useEffect } from "react";
import { withSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { canvasPreview } from "./canvasPreview";

// hooks
import { useDebounceEffect } from "./useDebounceEffect";

// styles
import "react-image-crop/dist/ReactCrop.css";

// components
import ModModal from "../ModModal";

// consts
import { LABELS, STRINGS } from "Shared/Constants";

// utils
import { errorSnackBar } from "Shared/Utilities";

// actions
import { startLoader } from "Redux/Actions/Loader";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}
const scale = 1;
const rotate = 0;
const aspect = 16 / 9;

// ref dimension
const pixelRatio = Math.min(window.innerWidth, window.innerHeight);

const MAX_REF_DIMENSION_FOR_COND_CROPPING = 480;
const CONDITONAL_CROP_AREA_DIMENTIONS = {
  WIDTH: 315,
  HEIGHT: 160,
};

function ReactEasyCropper({
  enqueueSnackbar,
  setFieldValue,
  yourImage,
  setLocalImage,
  handleProfileImageChange,
}) {
  const dispatch = useDispatch();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [maxWidth, setMaxWidth] = useState();
  const [maxHeight, setMaxHeight] = useState();

  const handleSelectImage = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      setImgSrc(URL.createObjectURL(file));
    }
  };
  useEffect(() => {
    if (pixelRatio < MAX_REF_DIMENSION_FOR_COND_CROPPING) {
      setMaxWidth(CONDITONAL_CROP_AREA_DIMENTIONS.WIDTH);
      setMaxHeight(CONDITONAL_CROP_AREA_DIMENTIONS.HEIGHT);
    } else {
      setMaxWidth(pixelRatio * 0.5);
      setMaxHeight(pixelRatio * 0.5);
    }
    if (yourImage) {
      handleSelectImage(yourImage);
      return;
    }
  }, [yourImage]);

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function onDownloadCropClick() {
    dispatch(startLoader(true));
    if (!previewCanvasRef.current) {
      dispatch(startLoader(false));
      enqueueSnackbar(STRINGS.CROP_CANVAS_NOT_EXIST, errorSnackBar);
    }

    const canvas = previewCanvasRef.current;
    canvas.toBlob((blob) => {
      if (!blob) {
        dispatch(startLoader(false));
        enqueueSnackbar(STRINGS.FAILED_TO_SELECT_IMAGE, errorSnackBar);
        setLocalImage(false);
        return;
      }

      const file = new File([blob], "profile.jpeg", { type: blob.type });
      handleProfileImageChange(file);
      setFieldValue("file", {
        file,
        preview: URL.createObjectURL(file),
      });
      setLocalImage(false);
    });
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        );
      }
    },
    100,
    [completedCrop, scale, rotate],
  );
  const handleCloseCoverMedia = () => {
    setImgSrc(null);
    setLocalImage(false);
  };
  return (
    <ModModal
      isOpen={imgSrc}
      handleToggle={handleCloseCoverMedia}
      title={LABELS.EDIT_PHOTO}
    >
      <div className="App">
        <div className="Crop-Controls">
          <div className="btn_position pb-3">
            <button className="btn btn-primary" onClick={onDownloadCropClick}>
              Done
            </button>
          </div>
        </div>

        {!!imgSrc && (
          <ReactCrop
            keepSelection={true}
            maxHeight={maxHeight}
            maxWidth={maxWidth}
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
        {!!completedCrop && (
          <div className="d-none">
            <div>
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: "1px solid black",
                  objectFit: "contain",
                  width: completedCrop.width,
                  height: completedCrop.height,
                }}
              />
            </div>
            <div>
              <button onClick={onDownloadCropClick}>Done</button>
            </div>
          </div>
        )}
      </div>
    </ModModal>
  );
}
export default withSnackbar(ReactEasyCropper);
