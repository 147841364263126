import { all, fork } from "redux-saga/effects";

import watchAuth from "./Auth";
import watchCareProgram from "./CareProgram";
import watchContact from "./Contact";

function* rootSaga() {
  yield all([fork(watchCareProgram), fork(watchAuth), fork(watchContact)]);
}

export default rootSaga;
