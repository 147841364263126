export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const API_URLS = {
  LOGIN: API_BASE_URL + API_VERSION + "",
};

export const STATUS_CODES = {
  SUCCESS: 200,
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
};

export const BASE_URL = API_BASE_URL;

export const API = {
  UPLOAD_FILE: `${API_BASE_URL}/v1/file/upload`,
  CARE_PROGRAM_APPLY: `${API_BASE_URL}/user/contactEmail`,
  CONTACT_US: `${API_BASE_URL}/user/contactEmail`,
  CARE_PROGRAM_CHILD: `${API_BASE_URL}/admin/child`,
};
