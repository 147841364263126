// libs
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Eye, Upload } from "tabler-icons-react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Delete } from "@material-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import * as yup from "yup";

// components
import CustomReactDatePicker from "Components/Atom/CustomReactDatePicker";
import ReactEasyCropper from "Components/Atom/ReactEasyCropper";
import ReactPhoneInput from "Components/Atom/ReactPhoneInput";
import TextField from "Components/Atom/TextField";

// constants
import {
  ERROR_MESSAGE,
  ERROR_STRING,
  Images,
  LABELS,
  ROUTE_CONSTANTS,
  STRINGS,
  Snackbar,
} from "Shared";
import { UTILITIES, isFileValid, types } from "Shared/Utilities";

// styles
import "./care-program.scss";

// actions
import {
  applyCareProgram,
  getChildCareProgram,
  uploadProfilePhoto,
} from "Redux/Actions/CareProgram";
import ChildStoryCard from "Components/Atom/ChildStoryCard";
import CustomModal from "Components/Atom/CustomModal";
import { donateLink } from "envData";

const fileSize = 5242880; // 5 mb
const formatSize = (size) => {
  return "5 Mb.";
};

const PLACEHOLDER = {
  YOUR_NAME: "Enter name",
  YOUR_SURNAME: "Enter surname",
  CHILD_NAME: "Enter child name",
  CHILD_SURNAME: "Enter child surname",
  EMAIL: "(name@example.com)",
  MESSAGE: "Enter message",
  PHONE: "Enter number",
};

const validationHandler = yup.object({
  your_name: yup
    .string()
    .trim()
    .matches(UTILITIES.REGEX.IS_NUMBER, ERROR_MESSAGE.NUMBER_NOT_ALLOWED)
    .required(ERROR_STRING.FIELD_REQUIRED)
    .test(
      "trim",
      "No leading or trailing spaces allowed",
      UTILITIES.notHaveWhitespaceOnEnds
    )
    .max(25, ERROR_MESSAGE.MAX_CHARACTER_COUNT_EXCEEDED),
  your_surname: yup
    .string()
    .trim()
    .matches(UTILITIES.REGEX.IS_NUMBER, ERROR_MESSAGE.NUMBER_NOT_ALLOWED)
    .required(ERROR_STRING.FIELD_REQUIRED)
    .test(
      "noWhitespace",
      ERROR_MESSAGE.WHITE_SPACE_NOT_ALLOWED,
      UTILITIES.whiteSpaceErrorHandler
    )
    .max(25, ERROR_MESSAGE.MAX_CHARACTER_COUNT_EXCEEDED),
  child_name: yup
    .string()
    .trim()
    .matches(UTILITIES.REGEX.IS_NUMBER, ERROR_MESSAGE.NUMBER_NOT_ALLOWED)
    .required(ERROR_STRING.FIELD_REQUIRED)
    .test(
      "trim",
      "No leading or trailing spaces allowed",
      UTILITIES.notHaveWhitespaceOnEnds
    )
    .max(25, ERROR_MESSAGE.MAX_CHARACTER_COUNT_EXCEEDED),
  child_surname: yup
    .string()
    .trim()
    .matches(UTILITIES.REGEX.IS_NUMBER, ERROR_MESSAGE.NUMBER_NOT_ALLOWED)
    .required(ERROR_STRING.FIELD_REQUIRED)
    .test(
      "noWhitespace",
      ERROR_MESSAGE.WHITE_SPACE_NOT_ALLOWED,
      UTILITIES.whiteSpaceErrorHandler
    )
    .max(25, ERROR_MESSAGE.MAX_CHARACTER_COUNT_EXCEEDED),
  dob: yup.string().trim().required(ERROR_STRING.FIELD_REQUIRED),
  email: yup
    .string()
    .trim()
    .email(ERROR_MESSAGE.VALID_EMAIL)
    .required(ERROR_STRING.FIELD_REQUIRED),
  phone: yup
    .string()
    .trim()
    .required("Field is required")
    .test(
      "noWhitespace",
      ERROR_MESSAGE.WHITE_SPACE_NOT_ALLOWED,
      UTILITIES.whiteSpaceErrorHandler
    )
    .max(25, ERROR_MESSAGE.MAX_CHARACTER_COUNT_EXCEEDED)
    .min(7, "Phone number must contain atleast 7 numbers"),
});

const initialValues = {
  your_name: "",
  your_surname: "",
  child_name: "",
  child_surname: "",
  email: "",
  phone: "",
  message: "",
  dob: "",
  file: "",
};

const CareProgram = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formikRef = useRef(null);
  const inputImageRef = useRef(null);
  const loading = useSelector((state) => state.loading.loading);

  const [isApplyModalOpen, setApplyModalOpen] = useState(false);
  const [startDate, setStartDate] = useState({ data: null, error: "" });
  const [localImage, setLocalImage] = useState(false);
  const [imgValue, setImgValue] = useState("");
  const resetData = () => {
    formikRef.current.resetForm();
    formikRef.current.setFieldValue("phone", "+61");
    setImgValue(null);
    setStartDate({ data: null, error: "" });
  };

  // Apply Care Request
  const handleSubmit = (values) => {
    if (!startDate?.data) {
      setStartDate({ ...startDate, error: ERROR_STRING.FIELD_REQUIRED });
      return;
    }

    const formData = UTILITIES.removeEmptyStringKeys({
      portal: 1,
      email: values?.email,
      phone: values?.phone,
      yourName: values?.your_name,
      yourSurname: values?.your_surname,
      childName: values?.child_name,
      childSurname: values?.child_surname,
      dob: startDate?.data ? moment(startDate?.data).format("DD/MM/YYYY") : "",
      message: values?.message,
      image: imgValue,
    });

    dispatch(
      applyCareProgram({
        formData: UTILITIES.removeUndefinedOrNullKeys(formData),
        success: (data) => {
          navigate({
            pathname: ROUTE_CONSTANTS.THANK_YOU_APPLY,
          });
        },
        fail: (errMsg) => {
          let msg = errMsg || STRINGS.SOMETHING_WENT_WRONG;
          Snackbar.error(msg);
        },
      })
    );
    resetData();
  };

  // Get Media URL
  const handleProfileImageChange = (imageData) => {
    const formData = new FormData();
    formData.append(STRINGS.MEDIA, imageData);
    dispatch(
      uploadProfilePhoto({
        formData: formData,
        success: (data) => {
          let msg = LABELS.UPLOAD_SUCCESS;
          setImgValue(data?.fileUrl);
          Snackbar.success(msg);
        },
        fail: (errMsg) => {
          let msg = errMsg || STRINGS.SOMETHING_WENT_WRONG;
          Snackbar.error(msg);
        },
      })
    );
  };

  const [childData, setChildData] = useState({ data: [] });

  const fetchChildProgram = () => {
    dispatch(
      getChildCareProgram({
        success: (data) => {
          setChildData({
            data: [...data?.childPost],
            count: data?.totalCount,
          });
        },
        enableLoader: true,
      })
    );
  };

  useEffect(() => {
    fetchChildProgram();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const toggleApplyNowModal = () => setApplyModalOpen(!isApplyModalOpen);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="section bg_grey careProgram_wrapper">
        <div className="container">
          <div className="common_titlebar text-center">
            <h2 className="h2">
              <span>{LABELS.CARE_PROGRAM.toUpperCase()}</span>
            </h2>
          </div>
          <div className="careProgram_wrapper-content text-start mb-4">
            <p className="text-justify">{LABELS.MINI_HEARTS_MESSAGE}</p>
          </div>
          <div
            className="Child_group_grid my-5"
            style={loading ? { minHeight: "320px" } : null}
          >
            {childData?.data &&
              childData?.data?.map((childStoryItem) => (
                <ChildStoryCard
                  key={`index_${childStoryItem?.id}`}
                  item={childStoryItem}
                />
              ))}
          </div>
          <div className="position-relative donationBtnGrp ">
            <div className="text-center d-flex justify-content-center align-items-center animating_logo">
              <Link
                type="link"
                className="donate_link"
                aria-current="page"
                target="_self"
                to={donateLink}
              >
                <img
                  src={Images.DonateNow}
                  className="img-fluid donateNow"
                  width="70"
                  alt="Donate Now heart"
                />
              </Link>
            </div>
            <button
              type="button"
              className="btn btn-primary btn-md applyNowLink"
              onClick={() => setApplyModalOpen(true)}
            >
              Apply now
            </button>
          </div>
        </div>
      </section>
      <CustomModal
        isOpen={isApplyModalOpen}
        handleToggle={toggleApplyNowModal}
        title={LABELS.APPLY_NOW}
      >
        <div className="custom_form_wrapper">
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationHandler}
            innerRef={(e) => (formikRef.current = e)}
          >
            {({ values, errors, touched, setFieldValue, setFieldTouched }) => (
              <Form>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label">{LABELS.FIRST_NAME}</label>
                    <div className="form-group mb-0">
                      <TextField
                        name={"your_name"}
                        placeholder={PLACEHOLDER.YOUR_NAME}
                        className={`form-control ${
                          touched?.your_name && errors?.your_name
                            ? "invalid-error"
                            : ""
                        }`}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3 ">
                    <label className="form-label">{LABELS.YOUR_SURNAME}</label>
                    <div className="form-group mb-0">
                      <TextField
                        name={"your_surname"}
                        placeholder={PLACEHOLDER.YOUR_SURNAME}
                        className={`form-control ${
                          touched?.your_surname && errors?.your_surname
                            ? "invalid-error"
                            : ""
                        }`}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">{LABELS.EMAIL}</label>
                    <div className="form-group mb-0">
                      <TextField
                        name={"email"}
                        placeholder={PLACEHOLDER.EMAIL}
                        className={`form-control ${
                          touched?.email && errors?.email ? "invalid-error" : ""
                        }`}
                        type="email"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">{LABELS.MOBILE_NUMBER}</label>
                    <Field
                      name="phone"
                      type="tel"
                      value={values.phone}
                      component={ReactPhoneInput}
                      className={`${
                        touched?.phone && errors?.phone ? "invalid-error" : ""
                      }`}
                      placeholder={PLACEHOLDER.PHONE}
                      onChange={(e) => {
                        let number_format = `+${e}`;
                        setFieldValue("phone", number_format);
                      }}
                    />
                    {errors?.phone ? (
                      <div className="error">
                        <ErrorMessage name="phone" />
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">{LABELS.CHILD_NAME}</label>
                    <div className="form-group mb-0">
                      <TextField
                        name={"child_name"}
                        placeholder={PLACEHOLDER.CHILD_NAME}
                        className={`form-control ${
                          touched?.child_name && errors?.child_name
                            ? "invalid-error"
                            : ""
                        }`}
                        type="test"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">{LABELS.CHILD_SURNAME}</label>
                    <div className="form-group mb-0">
                      <TextField
                        name={"child_surname"}
                        placeholder={PLACEHOLDER.CHILD_SURNAME}
                        className={`form-control ${
                          touched?.child_surname && errors?.child_surname
                            ? "invalid-error"
                            : ""
                        }`}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">{LABELS.CHILD_DOB}</label>
                    <div className="custom_date_picker mt-0">
                      <CustomReactDatePicker
                        value={startDate.data ? startDate.data : null}
                        name="dob"
                        showYearDropdown={true}
                        maxDate={new Date()}
                        className={`w-100 custom_Date_Picker ${
                          touched?.dob && errors?.dob ? "invalid-error" : ""
                        }`}
                        setDateValue={(e) => {
                          setFieldTouched("dob", true);
                          setFieldValue("dob", e);
                          setStartDate({ ...startDate, data: e, error: "" });
                        }}
                      />
                      <div className="error">
                        <ErrorMessage name="dob" />
                        {startDate.error ? (
                          <div className="dateError">{startDate.error}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3 gap-4">
                    <label className="form-label d-block d-sm-none">
                      Upload Photo
                    </label>
                    {localImage ? (
                      <ReactEasyCropper
                        yourImage={values.file.file}
                        setLocalImage={setLocalImage}
                        setFieldValue={setFieldValue}
                        handleProfileImageChange={handleProfileImageChange}
                      />
                    ) : null}

                    <Field name="file">
                      {() => {
                        return (
                          <>
                            <div className="button-wrapper d-flex justify-content-between align-items-center">
                              <label
                                htmlFor="upload"
                                className="btn btn-primary me-2"
                                tabIndex="0"
                                onClick={() => {
                                  inputImageRef.current.click();
                                }}
                              >
                                <span className="d-none d-sm-block">
                                  {" "}
                                  {LABELS.UPLOAD}{" "}
                                </span>
                                <i className="d-block d-sm-none">
                                  <Upload size={20} />
                                </i>
                                <input
                                  type="file"
                                  ref={inputImageRef}
                                  id="upload-file"
                                  className="account-file-input"
                                  hidden
                                  accept="image/png, image/jpeg"
                                  name={`file`}
                                  onChange={(e) => {
                                    if (e.target.files.length > 0) {
                                      setLocalImage(true);
                                      const isValid = isFileValid(
                                        e.target.files[0],
                                        types,
                                        fileSize,
                                        formatSize
                                      );
                                      if (!isValid.status) {
                                        Snackbar.error(isValid?.message);
                                        return;
                                      }
                                      setFieldValue("file", {
                                        file: e.target.files[0],
                                        preview: URL.createObjectURL(
                                          e.target.files[0]
                                        ),
                                      });
                                    }
                                  }}
                                />
                              </label>
                              {imgValue ? (
                                <>
                                  <div className="d-flex gap-3 justify-content-around align-items-center modal_actions">
                                    <span
                                      className="my-cursor-pointer view_action"
                                      onClick={() =>
                                        window.open(imgValue, "_self")
                                      }
                                      title="View"
                                    >
                                      <Eye />
                                    </span>
                                    <span
                                      className="my-cursor-pointer delete_action"
                                      onClick={() => {
                                        setImgValue("");
                                        setFieldValue("file", "");
                                      }}
                                      title="Remove"
                                    >
                                      <Delete />
                                    </span>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </>
                        );
                      }}
                    </Field>
                    {errors?.file ? (
                      <div className="error">
                        <ErrorMessage name="file" />
                      </div>
                    ) : null}
                  </div>

                  <div className="col-md-12 form-group">
                    <label className="form-label">
                      {LABELS.DESCRIPTION_CHILD}
                    </label>
                    <Field
                      className={`form-control ${
                        touched?.message && errors?.message
                          ? "invalid-error"
                          : ""
                      }`}
                      placeholder={PLACEHOLDER.MESSAGE}
                      as="textarea" // Use "as" prop to specify the type of input
                      name="message"
                      id="message"
                      rows="3" // Specify the number of visible text lines
                    />
                    <ErrorMessage
                      name="message"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-md btn-primary text-capitalize"
                    >
                      {LABELS.APPLY_NOW}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </CustomModal>
    </>
  );
};

export default CareProgram;
