import {
  APPLY_CARE_PROGRAM,
  CARE_CHILD,
  UPLOAD_PROFILE_PHOTO,
} from "./ActionTypes";

export const uploadProfilePhoto = (payload) => {
  return {
    type: UPLOAD_PROFILE_PHOTO,
    payload,
  };
};

export const applyCareProgram = (payload) => {
  return {
    type: APPLY_CARE_PROGRAM,
    payload,
  };
};

export const getChildCareProgram = (payload) => {
  return {
    type: CARE_CHILD,
    payload,
  };
};
