import { donateLink } from "envData";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Images, LABELS, STRING_NUMBER } from "Shared";
import WOW from "wowjs";

const CommunityCardData = [
  {
    id: STRING_NUMBER.ONE,
    imageUrl: Images.diversity,
    title: LABELS.DIVERSITY,
    description: LABELS.GLOBAL_ORGANISATION,
  },
  {
    id: STRING_NUMBER.TWO,
    imageUrl: Images.focussed,
    title: LABELS.FOCUSSED_GOAL,
    description: LABELS.FOCUSSED_GOAL_DESC,
  },
  {
    id: STRING_NUMBER.THREE,
    imageUrl: Images.efficient,
    title: LABELS.EFFICIENT_TECHNOLOGY,
    description: LABELS.EFFICIENT_TECHNOLOGY_DESC,
  },
  {
    id: STRING_NUMBER.FOUR,
    imageUrl: Images.experienced,
    title: LABELS.EXPERIENCED_MANAGEMENT,
    description: LABELS.EXPERIENCED_MANAGEMENT_DESC,
  },
];

const InsipirationCommunity = () => {
  const transitionRef = useRef(1);
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  return (
    <>
      <section className="section bg_grey inspiration_secn">
        <div className="container">
          <div className="row mt-3 mb-5 wow fadeInUp">
            <div className="col-lg-6">
              <div className="common_titlebar text-left">
                <h2 className="h2">{LABELS.INSPIRATION_ASSIS_COM}</h2>
              </div>
              <div className="inspiration_secn_data my-4">
                <p>{LABELS.INSPIRATION_MESSAGE}</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text-center d-flex justify-content-center align-items-center w-75 m-auto bg-white p-md-5 p-4 pulsating_logo">
                <Link
                  type="link"
                  className="donate_link"
                  aria-current="page"
                  target="_self"
                  to={donateLink}
                >
                  <img
                    src={Images.DonateNow}
                    className="img-fluid donateNow"
                    width="200"
                    alt="Donate Now heart"
                  />
                </Link>
              </div>
            </div>
          </div>

          {/* group secn card */}
          <div className="inspired_blocks">
            <div className="row my-md-3 mt-3 mb-0">
              {CommunityCardData.map((item, ind) => {
                return (
                  <div
                    className="col-lg-3 col-md-6 col-sm-6 col-12 mb-lg-0 mb-3  wow fadeInUp"
                    key={`ind_${item?.id}`}
                    data-wow-duration={`0.${transitionRef.current + ind}s`}
                    data-wow-delay={`0.${ind + 3}s`}
                  >
                    <div className="text-center inspired_blocks_card ">
                      <em>
                        <img
                          src={item?.imageUrl}
                          alt="technoiocn"
                          width="50"
                          height={50}
                        />
                      </em>
                      <div className="d-flex flex-column text-center inspired_blocks_content">
                        <h6 className="h6 mb-0">{item?.title}</h6>
                        <span>{item?.description}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InsipirationCommunity;
