// Loader
export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";
export const SET_SIDE_BAR = "SET_SIDE_BAR";

// upload image
export const UPLOAD_PROFILE_PHOTO = "UPLOAD_PROFILE_PHOTO";
export const APPLY_CARE_PROGRAM = "APPLY_CARE_PROGRAM";
export const CARE_CHILD = "CARE_CHILD";

// Contact us
export const CONTACT = "CONTACT";
