import { all, put, takeLatest } from "redux-saga/effects";
import {
  APPLY_CARE_PROGRAM,
  CARE_CHILD,
  UPLOAD_PROFILE_PHOTO,
} from "Redux/Actions/ActionTypes";
import { startLoader, stopLoader } from "Redux/Actions/Loader";
import { API, STATUS_CODES } from "Services/Api/Constants";
import { getRequest, postRequest } from "Shared/Axios";

function* uploadProfilePhoto({ payload }) {
  try {
    yield put(startLoader());
    const { data, status } = yield postRequest({
      API: API.UPLOAD_FILE,
      DATA: payload?.formData,
    });

    if (status === STATUS_CODES.SUCCESS) {
      if (payload?.success) {
        payload.success(data);
      }
    }
  } catch (error) {
    console.log(error);
    if (payload?.fail) {
      payload?.fail(error?.data?.message);
    }
  } finally {
    yield put(stopLoader());
  }
}

function* applyCareProgramRequest({ payload }) {
  try {
    yield put(startLoader());
    const { data, status } = yield postRequest({
      API: API.CARE_PROGRAM_APPLY,
      DATA: payload?.formData,
    });

    if (status === STATUS_CODES.SUCCESS) {
      if (payload?.success) {
        payload.success(data);
      }
    }
  } catch (error) {
    console.log(error);
    if (payload?.fail) {
      payload?.fail(error?.data?.message);
    }
  } finally {
    yield put(stopLoader());
  }
}

function* getChildCareProgramRequest({ payload }) {
  const isLoaderEnabled = payload?.enableLoader;
  try {
    if (isLoaderEnabled) yield put(startLoader());
    const { data, status } = yield getRequest({
      API: API.CARE_PROGRAM_CHILD,
    });

    if (status === STATUS_CODES.SUCCESS) {
      if (payload?.success) {
        payload.success(data?.data);
      }
    }
  } catch (error) {
    console.log(error);
    if (payload?.fail) {
      payload?.fail(error?.data?.message);
    }
  } finally {
    if (isLoaderEnabled) yield put(stopLoader());
  }
}

function* watchCareProgram() {
  yield all([
    takeLatest(APPLY_CARE_PROGRAM, applyCareProgramRequest),
    takeLatest(UPLOAD_PROFILE_PHOTO, uploadProfilePhoto),
    takeLatest(CARE_CHILD, getChildCareProgramRequest),
  ]);
}

export default watchCareProgram;
