import SubHeader from "Components/Atom/SubHeader";
import { IMAGE_SIZES, Images, STRINGS } from "Shared";
import "./common_pages.scss";
import { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SubHeader heading={STRINGS.PRIVACY_POLICY.TITLE} isFooter={true} />

      <div className="container py-md-5 py-4 common_pages">
        <div className="common_policy my-4">
          <div className="policy_secn">
            <h3 className="h4">{STRINGS.PRIVACY_POLICY.TITLE}</h3>
            <p>{STRINGS.PRIVACY_POLICY.PARA_1}</p>
            <p>{STRINGS.PRIVACY_POLICY.PARA_2}</p>
          </div>
          <div className="policy_secn">
            <h3 className="h4">{STRINGS.PRIVACY_POLICY.HEADING_1}</h3>
            <p>{STRINGS.PRIVACY_POLICY.PARA_3}</p>
          </div>
          <div className="policy_secn">
            <h3 className="h4">{STRINGS.PRIVACY_POLICY.HEADING_2}</h3>
            <p>{STRINGS.PRIVACY_POLICY.PARA_4}</p>
            <p>{STRINGS.PRIVACY_POLICY.PARA_5}</p>
            <ul>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.FIRST.LIST.FIRST}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.FIRST.LIST.SECOND}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.FIRST.LIST.THIRD}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.FIRST.LIST.FOURTH}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.FIRST.LIST.FIFTH}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.FIRST.LIST.SIX}</li>
            </ul>

            <p>{STRINGS.PRIVACY_POLICY.PARA_6}</p>
            <p>{STRINGS.PRIVACY_POLICY.PARA_7}</p>
            <p>{STRINGS.PRIVACY_POLICY.PARA_8}</p>
          </div>
          <div className="policy_secn">
            <h3 className="h4">{STRINGS.PRIVACY_POLICY.HEADING_3}</h3>
            <p>{STRINGS.PRIVACY_POLICY.PARA_9}</p>
            <ul>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.SECOND.LIST.FIRST}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.SECOND.LIST.SECOND}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.SECOND.LIST.THIRD}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.SECOND.LIST.FOURTH}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.SECOND.LIST.FIFTH}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.SECOND.LIST.SIX}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.SECOND.LIST.SEVEN}</li>
            </ul>
          </div>
          <div className="policy_secn">
            <h3 className="h4">{STRINGS.PRIVACY_POLICY.HEADING_4}</h3>
            <p>{STRINGS.PRIVACY_POLICY.PARA_10}</p>
            <ul>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.THIRD.LIST.FIRST}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.THIRD.LIST.SECOND}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.THIRD.LIST.THIRD}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.THIRD.LIST.FOURTH}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.THIRD.LIST.FIFTH}</li>
            </ul>
          </div>
          {/*  */}
          <div className="policy_secn">
            <h3 className="h4">{STRINGS.PRIVACY_POLICY.HEADING_5}</h3>
            <p>{STRINGS.PRIVACY_POLICY.PARA_11}</p>
            <ul>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.FOURTH.LIST.FIRST}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.FOURTH.LIST.SECOND}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.FOURTH.LIST.THIRD}</li>
            </ul>
          </div>
          {/*  */}
          <div className="policy_secn">
            <h3 className="h4">{STRINGS.PRIVACY_POLICY.HEADING_6}</h3>
            <p>{STRINGS.PRIVACY_POLICY.PARA_12}</p>
            <p>{STRINGS.PRIVACY_POLICY.PARA_13}</p>
            <ul>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.FIFTH.LIST.FIRST}</li>

              <li>
                {STRINGS.PRIVACY_POLICY.UNORDER_LIST.FIFTH.LIST.SECOND.PARA_1}
                <ul>
                  <li>
                    {
                      STRINGS.PRIVACY_POLICY.UNORDER_LIST.FIFTH.LIST.SECOND
                        .UNORDER_LIST.LIST_ONE
                    }
                  </li>
                  <li>
                    {" "}
                    {
                      STRINGS.PRIVACY_POLICY.UNORDER_LIST.FIFTH.LIST.SECOND
                        .UNORDER_LIST.LIST_TWO
                    }
                  </li>
                </ul>
              </li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.FIFTH.LIST.THIRD}</li>
            </ul>
          </div>
          {/*  */}
          <div className="policy_secn">
            <h3 className="h4">{STRINGS.PRIVACY_POLICY.HEADING_7}</h3>
            <p>{STRINGS.PRIVACY_POLICY.PARA_14}</p>

            <ul>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.SIX.LIST.FIRST}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.SIX.LIST.SECOND}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.SIX.LIST.THIRD}</li>
            </ul>
          </div>
          {/*  */}
          <div className="policy_secn">
            <h3 className="h4">{STRINGS.PRIVACY_POLICY.HEADING_13}</h3>
            <h5 className="h5">{STRINGS.PRIVACY_POLICY.HEADING_14}</h5>
            <p>{STRINGS.PRIVACY_POLICY.PARA_15}</p>
            <p>{STRINGS.PRIVACY_POLICY.PARA_16}</p>
            <h5 className="h5">{STRINGS.PRIVACY_POLICY.HEADING_8}</h5>
            <p>{STRINGS.PRIVACY_POLICY.PARA_17}</p>

            <h5 className="h5">{STRINGS.PRIVACY_POLICY.HEADING_9}</h5>
            <p>{STRINGS.PRIVACY_POLICY.PARA_18}</p>
            <p>{STRINGS.PRIVACY_POLICY.PARA_19}</p>
          </div>
          <div className="policy_secn">
            <h3 className="h4">{STRINGS.PRIVACY_POLICY.HEADING_10}</h3>
            <p>{STRINGS.PRIVACY_POLICY.PARA_20}</p>
          </div>
          <div className="policy_secn">
            <h3 className="h4">{STRINGS.PRIVACY_POLICY.HEADING_11}</h3>
            <p>{STRINGS.PRIVACY_POLICY.PARA_21}</p>
            <p>{STRINGS.PRIVACY_POLICY.PARA_22}</p>
            <ul>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.SEVEN.LIST.FIRST}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.SEVEN.LIST.SECOND}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.SEVEN.LIST.THIRD}</li>
            </ul>
          </div>
          <div className="policy_secn">
            <h3 className="h4">{STRINGS.PRIVACY_POLICY.HEADING_12}</h3>
            <p>{STRINGS.PRIVACY_POLICY.PARA_23}</p>
            <ul>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.EIGHT.LIST.FIRST}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.EIGHT.LIST.SECOND}</li>
              <li>{STRINGS.PRIVACY_POLICY.UNORDER_LIST.EIGHT.LIST.THIRD}</li>
            </ul>
          </div>
          <div className="policy_secn">
            <h3 className="h4">{STRINGS.PRIVACY_POLICY.HEADING_15}</h3>
            <h2 className="h3">{STRINGS.PRIVACY_POLICY.HEADING_16}</h2>
            <p>{STRINGS.PRIVACY_POLICY.PARA_24}</p>
            <div className="address-pattern mb-3">
              <div className="d-flex flex-column align-items-start flex-wrap">
                <strong>{STRINGS.PRIVACY_POLICY.EMAIL.LABEL}</strong>
                <span>{STRINGS.PRIVACY_POLICY.EMAIL.ADDRESS}</span>
              </div>
            </div>
            <div className="address-pattern mb-3">
              <div className="d-flex flex-column align-items-start flex-wrap">
                <strong>{STRINGS.PRIVACY_POLICY.CONTACT.LABEL}</strong>
                <span>{STRINGS.PRIVACY_POLICY.CONTACT.NUMBER}</span>
              </div>
            </div>
            <div className="address-pattern">
              <strong>{STRINGS.PRIVACY_POLICY.POST.LABEL}</strong>
              <div className="row">
                <div className="col-sm-4 col-lg-2">
                  {STRINGS.PRIVACY_POLICY.POST.VALUE_1}
                </div>
                <div className="col-sm-8 col-lg-10">
                  {STRINGS.PRIVACY_POLICY.POST.VALUE_2}
                </div>
              </div>

              <div className="row">
                <div className="col-sm-4 col-lg-2">
                  {STRINGS.PRIVACY_POLICY.ADDRESS.LABEL}
                </div>
                <div className="col-sm-8 col-lg-10">
                  {STRINGS.PRIVACY_POLICY.ADDRESS.VALUE_1}
                  <br />
                  {STRINGS.PRIVACY_POLICY.ADDRESS.VALUE_2}
                  <br />
                  {STRINGS.PRIVACY_POLICY.ADDRESS.VALUE_3}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
