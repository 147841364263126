// components
import CustomSliderComponent from "Components/Atom/CustomSliderComponent";
import { STRING_NUMBER } from "Shared";

// libs
import { useEffect, useRef, useState } from "react";
import WOW from "wowjs";

const testomonialData = [
  {
    id: STRING_NUMBER.ONE,
    auther_name: "Anna",
    data: `"Mini Hearts Foundation exemplifies the true essence of compassion and generosity, providing assistance to children and families in their darkest times of need. They have given us hope, strength, and the belief that together, we can overcome any obstacle life throws our way."`,
  },
  {
    id: STRING_NUMBER.TWO,
    auther_name: "Sandra",
    data: `"Thank you, Mini Hearts, for your empathy and compassion. Your assistance has made an immense difference in Isabella's life and our entire family is grateful for the love and care you've shown us."`,
  },
  {
    id: STRING_NUMBER.THREE,
    auther_name: "Chantal Mancini",
    data: `"We will never be able to express how grateful we are for Justin and family, and the Mini Hearts foundation for all their ongoing support for our daughter Mia."`,
  },
  {
    id: STRING_NUMBER.FOUR,
    auther_name: "Monica Coenraads  Rhett / CEO - Rett Syndrom Research Trust",
    data: `"It's more critical than ever that we keep this exciting momentum going. We couldn't do this without you and others who care so much about our outcomes. Thank you so much for your ongoing support."`,
  },
];

const Testimonials = () => {
  const customSliderRef = useRef(null);
  const endSlideCount = useRef(STRING_NUMBER.FOUR);
  const firstDashWidth = useRef(STRING_NUMBER.FIVE);

  const [slideCountData, setSlideCountData] = useState(STRING_NUMBER.ONE);

  const handlePageChange = ({ totalSlidesCount, index }) => {
    const percentProgress = (index / (totalSlidesCount - 1)) * 100;
    setSlideCountData(index + 1);
    document.getElementById("progress_bar").style.width =
      percentProgress + Number(firstDashWidth.current) + "%";
  };

  useEffect(() => {
    document.getElementById("progress_bar").style.width =
      Number(firstDashWidth.current) + "%";
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <>
      <div className="testimonialblock wow">
        <div className="common_titlebar text-center">
          <h2 className="h2">Testimonials</h2>
        </div>
        <div className="testimonial_slider mt-4">
          <CustomSliderComponent
            sliderRef={customSliderRef}
            onPageChange={handlePageChange}
          >
            {testomonialData?.length
              ? testomonialData.map((item, ind) => {
                  return (
                    <div className="testimonial_card" key={`index_${item?.id}`}>
                      <p>{item?.data}</p>
                      <span className="by-label">
                        {" "}
                        &nbsp; {item?.auther_name}
                      </span>
                    </div>
                  );
                })
              : null}
          </CustomSliderComponent>
          <div className="sliderProgressbar ">
            <div className="d-flex flex-row justify-content-between align-items-center w-100 position-relative">
              <span>{STRING_NUMBER.ZERO + "" + slideCountData}</span>
              <div className="progress-bar">
                <div id="progress_bar" className="progress"></div>
              </div>
              <span>{STRING_NUMBER.ZERO + "" + endSlideCount.current}</span>
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
};

export default Testimonials;
