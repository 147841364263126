import { CONTACT } from "Redux/Actions/ActionTypes";
import { startLoader, stopLoader } from "Redux/Actions/Loader";
import { API, STATUS_CODES } from "Services/Api/Constants";
import { postRequest } from "Shared/Axios";
import { all, put, takeLatest } from "redux-saga/effects";

function* contactRequest({ payload }) {
  try {
    yield put(startLoader());
    const { data, status } = yield postRequest({
      API: API.CONTACT_US,
      DATA: payload?.formData,
    });

    if (status === STATUS_CODES.SUCCESS) {
      if (payload?.success) {
        payload?.success(data?.message);
      }
    }
  } catch (error) {
    console.log(error, error?.status);
    if (payload?.fail) {
      payload?.fail(error?.data?.message);
    }
  } finally {
    yield put(stopLoader());
  }
}

function* watchContact() {
  yield all([takeLatest(CONTACT, contactRequest)]);
}

export default watchContact;
