import SubHeader from "Components/Atom/SubHeader";
import { Images, LABELS } from "Shared";
import { STRINGS } from "Shared";
import moment from "moment";
import React, { useEffect } from "react";

const TermsConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SubHeader heading={LABELS.TERMS_AND_CONDITIONS} isFooter={true} />

      <div className="container py-md-5 py-4 common_pages">
        <p>
          In this Agreement, "Mini Hearts" means Mini Hearts Foundation Pty Ltd
          ACN 670 958 111 and "you" means you whether in your personal capacity
          or as an authorised representative on behalf of another person,
          business or entity.
        </p>
        {/* ol list with nesting */}
        <div className="nesting_list">
          <ol className="nested-ordered-list">
            <li>
              <strong>Definitions</strong>
              <ol>
                <li>
                  In this Agreement, the following definitions apply unless the
                  context requires otherwise:
                  <ol type="a" className="type-a">
                    <li>
                      <strong>Agreement</strong> means the Donation made by you
                      to Mini Hearts and accepted by Mini Hearts together with
                      these Terms.
                    </li>
                    <li>
                      <strong>Business Day</strong> means a day that is not a
                      weekend or public holiday.
                    </li>
                    <li>
                      <strong>Donor</strong> means any person whether in their
                      personal capacity or as an authorised representative on
                      behalf of another person, business or entity who makes a
                      donation to Mini Hearts.
                    </li>
                    <li>
                      <strong>Privacy Act</strong> means Privacy Act 1988 (Cth)
                    </li>
                    <li>
                      <strong>Terms</strong> means these Terms and Conditions.
                    </li>
                    <li>
                      <strong>Website</strong> means www.minihearts.org.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            {/* 2 */}
            <li>
              <strong>Acceptance</strong>
              <ol>
                <li>
                  By donating to Mini Hearts, you are agreeing to these terms
                  and conditions.
                </li>
              </ol>
            </li>
            {/* 3 */}
            <li>
              <strong>Donations (general)</strong>
              <ol>
                <li>
                  Mini Hearts will determine where donations are directed within
                  its activities and charitable objectives. Where a donation is
                  designated for a specific purpose, Mini Hearts will use its
                  reasonable endeavours to direct that donation to that purpose.
                </li>

                <li>
                  Donations of $2 or more to Mini Hearts are tax deductible.
                  Donors making donations of $2 or more will receive a receipt
                  for each donation.
                </li>
                <li>
                  Donations can be made through the Mini Hearts Website. The
                  method of payment on the Website is by credit card. The credit
                  cards accepted by the Website include but are not limited to
                  Visa, Mastercard and American Express. All transactions are
                  processed in Australian dollars.
                </li>
              </ol>
            </li>
            {/* 4 */}
            <li>
              <strong>Regular Donations</strong>
              <ol>
                <li>
                  Regular donations can be set up on a weekly, fortnightly or
                  monthly basis and you will be required to setup as a monthly
                  member and enter your credit card details. If your option is
                  to be direct debited monthly through the donations page
                  without the need to be a member, then you are bound by the
                  terms of this document.
                </li>
                <li>
                  If you authorise a recurring donation, your credit card
                  details are stored by our payment processor in accordance with
                  payment card industry standards.
                </li>
                <li>
                  If you wish to cancel, defer or make changes to your regular
                  donation arrangement, you will be required to notify us at
                  least 14 days before the next donation day by calling{" "}
                  <strong>+61 3 9221 6130 </strong>
                  or Emailing: accounts@minihearts.org
                </li>
                <li>
                  You must ensure that there are sufficient clear funds
                  available in your account for deductions to be made.
                </li>
              </ol>
            </li>
            {/* 5 */}
            <li>
              <strong>Refunds</strong>
              <ol>
                <li>
                  Any request for the refund of a donation made to Mini Hearts
                  by a Donor will be considered and determined on a case-by-case
                  basis.
                </li>
                <li>
                  Mini Hearts will fully examine all requests for refunds and
                  endeavour to ensure that genuine errors are rectified, however
                  Mini Hearts is under no obligation to give refunds and the
                  decision on refunds will be at Mini Heart’s discretion.
                </li>
                <li>
                  Mini Hearts will endeavour to refund donations in the
                  following circumstances:
                  <ol>
                    <li>
                      If an error is made in making an online donation; or
                    </li>
                    <li>
                      If a Donor’s banking details were fraudulently obtained
                      and used; or
                    </li>
                    <li>
                      If a Donor had previously requested that regular donations
                      be cancelled, however the donations have continued. In
                      this case, the refund will be capped at the last debit
                      amount unless you can provide proof of the original
                      cancellation request; or
                    </li>
                    <li>
                      If there are grounds of financial hardship. In this case,
                      the refund will be capped to the last debit amount.
                    </li>
                  </ol>
                </li>
                <li>
                  All refund requests must be made in writing within 30 days of
                  the date the donation was made. The written refund request
                  must include the details of the initial transaction including
                  date, donation amount, Donor's name, ID, tax invoice number
                  and the reason for the refund request.
                </li>
                <li>
                  Refund requests must be sent to:
                  <br />
                  <strong>Email:</strong> accounts@minihearts.org; or
                  <br />
                  <strong>Mail:</strong> Level 27 / 101 Collins St, Melbourne
                  Victoria, Australia 3000
                </li>
                <li>
                  If the amount of a donation is adjusted by Mini Hearts, the
                  original receipt issued for the incorrect amount will become
                  invalid and a new receipt will be issued for the amount of the
                  adjusted donation.
                </li>
                <li>
                  Mini Hearts reserves the right to pass any refund transaction
                  charges on to the Donor.
                </li>
                <li>
                  Approved refunds will be paid to the bank account or credit
                  card originally debited within ten (10) business days of the
                  decision.
                </li>
              </ol>
            </li>
            {/* 5 */}
            {/* data collecton */}
            <li>
              <strong>Data Collection and Usage</strong>
              <ol>
                <li>
                  For the purposes of transparent accounting and good governance
                  the names and contact details of donors will be recorded
                  securely by Mini Hearts. Mini Hearts may be required to
                  confirm donation details under Australian taxation laws.
                </li>
                <li>
                  Donor details may be used judiciously from time to time by
                  Mini Hearts to contact former donors to inform them of the
                  organisation’s current fundraising initiatives.
                </li>
                <li>
                  The details of Donors will not be shared or sold with any
                  entities.
                </li>
                <li>
                  Donors may opt out of any future contact with Mini Hearts at
                  any time and this request will be honored by Mini Hearts as
                  soon as it is received.
                </li>
              </ol>
            </li>
            {/* 6 */}
            {/* 7 */}
            <li>
              <strong>Privacy</strong>
              <ol>
                <li>
                  Any personal information disclosed by you to Mini Hearts is
                  subject to and will be handled in accordance with the Privacy
                  Act and the Australian Privacy Principles.
                </li>
              </ol>
            </li>
            {/* 8 */}
            <li>
              <strong>Limitation of Liability</strong>
              <ol>
                <li>
                  Mini Hearts shall not be bound by any other covenants,
                  representations or warranties other than those specified in
                  these Terms.
                </li>
                <li>
                  While we do not purport to limit or otherwise affect the
                  operation of State and Federal laws in Australia, you agree
                  not to hold us liable for any loss, damage or expense incurred
                  (on either an express or an implied basis) from or by the acts
                  or omissions of us, our employees, agents or solicitors whilst
                  we act in accordance with these Terms.
                </li>
              </ol>
            </li>
            {/* 9 */}
            <li>
              <strong>Waiver</strong>
              <ol>
                <li>
                  A party does not waive a right, power or remedy if it fails to
                  exercise or delays in exercising the right, power or remedy. A
                  single or partial exercise of a right, power or remedy does
                  not prevent another or further exercise of that or another
                  right, power or remedy. A waiver of a right, power or remedy
                  must be in writing and signed by the party giving the waiver.
                </li>
              </ol>
            </li>
            {/* 10 */}
            <li>
              <strong>Severability</strong>
              <ol>
                <li>
                  Each of these Terms are severable from the others and
                  severance of a term will not affect any other term.
                </li>
              </ol>
            </li>
            {/* 11 */}
            <li>
              <strong>Entire Agreement</strong>
              <ol>
                <li>
                  This Agreement constitutes the entire agreement between the
                  parties in connection with its subject matter and supersedes
                  all previous agreements, understandings or communications
                  between the parties in connection with its subject matter.
                </li>
              </ol>
            </li>
            {/* 12 */}
            <li>
              <strong>Jurisdiction and law</strong>
              <ol>
                <li>
                  Unless otherwise agreed in writing by us, at our sole
                  selection, the Courts of any State or Territory in Australia
                  will have exclusive jurisdiction in relation to all matters
                  whatsoever concerning these Terms. You irrevocably waive any
                  objection to the venue selected by us in relation to any legal
                  proceedings concerning these Terms. The laws of the State or
                  Territory chosen by us for any such legal proceedings, will
                  govern these Terms.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default TermsConditions;
