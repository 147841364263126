import React from "react";

const AddressComp = () => {
  return (
    <>
      <div className="addressBar px-lg-5">
        <h2 className="h2">
          Get in touch
          <br /> with us.
        </h2>
        <p>
          If you would like to work with us or just want to get in touch, we’d
          love to hear from you!
        </p>
        <div className="row addressBarRow">
          <div className="d-flex flex-column align-items-start col-md-6 mb-4">
            <h5 className="h5">Official Email</h5>
            <span className="h6">hello@minihearts.org</span>
          </div>
          <div className="d-flex flex-column align-items-start col-md-6">
            <h5 className="h5">Address</h5>
            <span className="h6">
              Level 27, 101 Collins St MELBOURNE, VICTORIA AUSTRALIA. 3000
              <br />
              Ph: +61 3 9221 6130
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressComp;
