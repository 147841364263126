import React from "react";
import { Tooltip } from "@mui/material";

const CustomTooltip = ({
  title,
  placement = "top",
  arrow = true,
  children,
}) => {
  return (
    <Tooltip title={title} placement={placement} arrow={arrow}>
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
