import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";

const CustomSliderComponent = ({
  children,
  sliderRef,
  onPageChange = () => {},
}) => {
  const focusOnSlide = (slideIndex) => {
    sliderRef.current.slickGoTo(slideIndex);
  };

  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,

    afterChange: (index) => {
      const totalSlidesCount = sliderRef.current.innerSlider.state.slideCount;
      focusOnSlide(index);
      onPageChange({ totalSlidesCount, index });
    },
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const PreviousArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <title />
          <g data-name="Layer 2" id="Layer_2">
            <path d="M10.1,23a1,1,0,0,0,0-1.41L5.5,17H29.05a1,1,0,0,0,0-2H5.53l4.57-4.57A1,1,0,0,0,8.68,9L2.32,15.37a.9.9,0,0,0,0,1.27L8.68,23A1,1,0,0,0,10.1,23Z" />
          </g>
        </svg>
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={className + " " + "next-arrow"} onClick={onClick}>
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <title />
          <g data-name="Layer 2" id="Layer_2">
            <path d="M22,9a1,1,0,0,0,0,1.42l4.6,4.6H3.06a1,1,0,1,0,0,2H26.58L22,21.59A1,1,0,0,0,22,23a1,1,0,0,0,1.41,0l6.36-6.36a.88.88,0,0,0,0-1.27L23.42,9A1,1,0,0,0,22,9Z" />
          </g>
        </svg>
      </div>
    );
  };

  return (
    <div>
      {children ? (
        <Slider
          ref={sliderRef}
          className={`custom-slider`}
          {...sliderSettings}
          prevArrow={<PreviousArrow />}
          nextArrow={<NextArrow />}
        >
          {children}
        </Slider>
      ) : null}
    </div>
  );
};

export default CustomSliderComponent;
