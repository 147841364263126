// libs

// Constants
import { ROUTE_CONSTANTS } from "Shared/Routes";

// components
import ContactUs from "Components/Core/Footer/ContactUs";
import PrivacyPolicy from "Components/Core/Footer/PrivacyPolicy";
import TermsConditions from "Components/Core/Footer/TermsConditions";
import CareProgram from "Views/CareProgram";
import SinglePage from "Views/SinglePage";
import ThankyouApply from "Views/ThankyouApply";
import Leadership from "Views/leadership";

export const PUBLIC_ROUTES = [
  {
    path: ROUTE_CONSTANTS.DASHBOARD,
    element: <SinglePage />,
    title: "Mini Hearts",
  },
  {
    path: ROUTE_CONSTANTS.CARE_PROGRAM,
    element: <CareProgram />,
    title: "Care Program",
  },
  {
    path: ROUTE_CONSTANTS.THANK_YOU_APPLY,
    element: <ThankyouApply />,
    title: "Thank You",
  },
  {
    path: ROUTE_CONSTANTS.PRIVACY_POLICY_PAGE,
    element: <PrivacyPolicy />,
    title: "Privacy Policy",
  },
  {
    path: ROUTE_CONSTANTS.TERM_AND_CONDITIONS,
    element: <TermsConditions />,
    title: "Terms & Conditions",
  },
  {
    path: ROUTE_CONSTANTS.CONTACT_US,
    element: <ContactUs />,
    title: "Contact Us",
  },
  // {
  //   path: ROUTE_CONSTANTS.PRIZE_POOL,
  //   element: <PrizePool />,
  //   title: "Prize Pool",
  // },
  {
    path: ROUTE_CONSTANTS.LEADER_SHIP,
    element: <Leadership />,
    title: "Leadership",
  },
];
